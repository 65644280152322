import React, { useState } from "react";
import Slider from "react-slick";

const SuccessStories = () => {
    const [video, setVideo] = useState(1);
    const [currentSlide, setCurrentSlide] = useState(0);
    const opts = {
        height: "250",
        width: "250",
        playerVars: {
            autoplay: 1,
        },
    };
    const carouselSettings = {
        dots: true,
        arrows: false,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: false,
        autoplaySpeed: 0,
        speed: 300,
        pauseOnFocus: false,
        pauseOnHover: false,
        afterChange: (current) => setCurrentSlide(current),
        customPaging: (i) => {
            return (
                <div
                    className={
                        i === currentSlide ? "current_dot" : "carousel_dot"
                    }
                ></div>
            );
        },
    };

    const stories = [
        // {
        //     name: "Namita Shukla",
        //     age: 22,
        //     location: "Gurgaon",
        //     imgurl: "/assets/images/success-stories-namita.jpeg",
        //     videoId: "jZ6Ph6RPkEo",
        //     videourl: "https://www.youtube.com/embed/jZ6Ph6RPkEo",
        //     testimonial:
        //         "The team is very responsive and helpful! I especially love the yoga sessions, the instructors are great. My Health Coach caters to all my needs and keeps motivating me from time to time. The journey with Uvi has so far been amazing.",
        // },
        {
            name: "Haritha Guthimella",
            age: 26,
            location: "Hyderabad",
            imgurl: "/assets/images/success-stories-harita.jpg",
            testimonial:
                "I chose Uvi Health after searching through many options. I have seen changes in my body. I have lost weight and improved my stamina. Instructors at Uvi are very friendly and responsive. I love that their team check-ins on me every week and send me reminders for my fitness sessions.",
        },
        {
            name: "Sharanya Muthyam",
            age: 23,
            location: "Hyderabad",
            imgurl: "/assets/images/success-stories-sharanya.jpg",
            testimonial:
                "As a working woman tackling PCOS has been a challenge for me, Uvi Health’s overall support system easily fits in my busy routine. Their PCOS Yoga has worked wonders for me from the start itself. The Diet plan was helpful in tackling my irregular menstrual problems. The team is very proactive and tracks my health every week and sends much needed reminders.",
        },
        {
            name: "Seher Sajed",
            age: 19,
            location: "Mumbai",
            imgurl: "/assets/images/empty-dp.png",
            testimonial:
                "It has been two months since I joined the uvi program, I didn't have much expectation at first since I had  already been told a lot of things related to PCOS and how its incurable, stigmas such as lose weight to recover, Thankfully the Uvi team (the nutritionist who is ever ready to answer all questions, the yoga instructor who doesn't drain you out and highly encouraging support members) helped break all these stereotypes and simplified the journey of attaining a better lifestyle for me, my menstrual cycle have finally regulated, my breakouts and skin has become a lot clearer and the best part is that I didn't have to make a huge lifestyle change in order to attain these changes, it was simple changes that were flexible and easy to incorporate that made a major impact.",
        },
        {
            name: "Lippy Ramchandani",
            age: 25,
            location: "Mumbai",
            imgurl: "/assets/images/empty-dp.png",
            testimonial:
                "Your team at Uvi Health is doing such a great job. And of course thank you for being patient and following up regularly. It’s great to have someone to check on you, because patient then you feel more accountable for all your choices. I really appreciate it. The yoga classes are very well designed. I have a lot more stamina and feel energetic through the day. I can feel significant improvement in stress, diet, exercise and sleep also. Periodic updates from the dietician are helping me make the right food choices and understand what my body needs by following intuitive eating habits. Can't wait to see more changes. So hopeful and glad to be a part of such a wholesome community.",
        },
        {
            name: "Pooja Sachdeva",
            age: 26,
            location: "Delhi",
            imgurl: "/assets/images/success-stories-pooja.png",
            testimonial:
                "Personally I feel the sessions have been great. My anxiety has reduced and I've also seen some fat loss around my waist. The best thing is that I got my period on time, which is a big concern for me every month as I have PCOD.",
        },
    ];

    const startVideo = (videourl) => {
        if (videourl) {
            setVideo(1);
        }
    };

    return (
        <div>
            <div className="row" style={{ marginTop: "5em" }}>
                <div className="col-12">
                    <center>
                        <h2
                            style={{
                                fontWeight: 500,
                                fontSize: "2em",
                                color: "#4B4B6D",
                                marginBottom: "2em",
                            }}
                        >
                            Success Stories
                        </h2>
                    </center>
                </div>
            </div>

            <Slider
                {...carouselSettings}
                responsive={[
                    {
                        breakpoint: 500,
                        settings: {
                            // dots: false,
                        },
                    },
                ]}
            >
                {stories.map((val) => {
                    return (
                        <div>
                            <div
                                className="card reviews-card-2"
                                key={Math.random()}
                            >
                                {video === 0 ? (
                                    val.imgurl ? (
                                        <img
                                            className="card-img-top review-card-img-2"
                                            src={val.imgurl}
                                            alt="Card cap"
                                            onClick={() =>
                                                startVideo(val.videourl)
                                            }
                                        />
                                    ) : (
                                        <div className="empty-dp-2" />
                                    )
                                ) : val.videourl ? (
                                    <div className="landing-video-embed">
                                        <iframe
                                            height="250"
                                            width="250"
                                            title="Uvi Health Testimonial"
                                            src={val.videourl}
                                        ></iframe>
                                    </div>
                                ) : (
                                    <img
                                        className="card-img-top review-card-img-2"
                                        src={val.imgurl}
                                        alt="Card cap"
                                        onClick={() => startVideo(val.videourl)}
                                    />
                                )}
                                <div>
                                    <h2
                                        className="card-title"
                                        style={{
                                            fontSize: "1.2em",
                                            color: "#373750",
                                        }}
                                    >
                                        {`${val.name}, ${val.age}`}
                                    </h2>

                                    <h4
                                        className="card-title"
                                        style={{
                                            fontSize: "1em",
                                            color: "#373750",
                                        }}
                                    >
                                        {val.location}
                                    </h4>

                                    <h4
                                        className="card-text"
                                        style={{
                                            marginTop: "2em",
                                            fontSize: "1em",
                                            color: "#4B4B6D",
                                            fontWeight: 400,
                                            lineHeight: "1.3em",
                                        }}
                                    >
                                        {val.testimonial}
                                    </h4>
                                </div>
                            </div>
                        </div>
                    );
                })}
            </Slider>
        </div>
    );
};

export default SuccessStories;
