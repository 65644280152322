import React from 'react';

const ConnectHealthCoach = ({ handleTrialSwitch }) => {
    const handleTrialClick = () => {
        handleTrialSwitch();
    };

    return (
        <div className="connect-health-coach">
            <h2 className="landing-home-hero-1-1">
                Connect with our <br /> Health Coach
            </h2>

            <h4 className="landing-home-hero-2-2">
                Have any questions about our PCOS plan? Go ahead & book a call with us, we would love to help you out.
            </h4>

            <div className="row">
                <div className="col-12">
                    <a
                        className="btn btn-primary landing-page-submit btn-block"
                        href="https://uvihealth.in/health-coach"
                    >
                        <span className="landing-submit-text">Talk to a Health Coach</span>
                    </a>
                </div>
            </div>
        </div>
    );
};

export default ConnectHealthCoach;
