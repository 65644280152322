import React, { useState } from "react";
import Slack from "slack-node";
import queryString from "query-string";
import request from "request";
import classNames from "classnames";
import styles from "../../styleModules/style.module.css";

const UserForm = ({ handleChange }) => {
    const [name, setName] = useState("");
    const [mobileNumber, setMobileNumber] = useState("");
    const [countryCode, setCountryCode] = useState("91");
    const [submitted, setSubmitted] = useState(0);
    const [error, setError] = useState("");

    const countryCodeList = {
        Afghanistan: "93",
        Albania: "355",
        Algeria: "213",
        Andorra: "376",
        Angola: "244",
        Antarctica: "672",
        Argentina: "54",
        Armenia: "374",
        Aruba: "297",
        Australia: "61",
        Austria: "43",
        Azerbaijan: "994",
        Bahrain: "973",
        Bangladesh: "880",
        Belarus: "375",
        Belgium: "32",
        Belize: "501",
        Benin: "229",
        Bhutan: "975",
        Bolivia: "591",
        "Bosnia and Herzegovina": "387",
        Botswana: "267",
        Brazil: "55",
        "British Indian Ocean Territory": "246",
        Brunei: "673",
        Bulgaria: "359",
        "Burkina Faso": "226",
        Burundi: "257",
        Cambodia: "855",
        Cameroon: "237",
        Canada: "1",
        "Cape Verde": "238",
        "Central African Republic": "236",
        Chad: "235",
        Chile: "56",
        China: "86",
        "Christmas Island": "61",
        "Cocos Islands": "61",
        Colombia: "57",
        Comoros: "269",
        "Cook Islands": "682",
        "Costa Rica": "506",
        Croatia: "385",
        Cuba: "53",
        Curacao: "599",
        Cyprus: "357",
        "Czech Republic": "420",
        "Democratic Republic of the Congo": "243",
        Denmark: "45",
        Djibouti: "253",
        "East Timor": "670",
        Ecuador: "593",
        Egypt: "20",
        "El Salvador": "503",
        "Equatorial Guinea": "240",
        Eritrea: "291",
        Estonia: "372",
        Ethiopia: "251",
        "Falkland Islands": "500",
        "Faroe Islands": "298",
        Fiji: "679",
        Finland: "358",
        France: "33",
        "French Polynesia": "689",
        Gabon: "241",
        Gambia: "220",
        Georgia: "995",
        Germany: "49",
        Ghana: "233",
        Gibraltar: "350",
        Greece: "30",
        Greenland: "299",
        Guatemala: "502",
        Guinea: "224",
        "Guinea-Bissau": "245",
        Guyana: "592",
        Haiti: "509",
        Honduras: "504",
        "Hong Kong": "852",
        Hungary: "36",
        Iceland: "354",
        India: "91",
        Indonesia: "62",
        Iran: "98",
        Iraq: "964",
        Ireland: "353",
        Israel: "972",
        Italy: "39",
        "Ivory Coast": "225",
        Japan: "81",
        Jordan: "962",
        Kazakhstan: "7",
        Kenya: "254",
        Kiribati: "686",
        Kosovo: "383",
        Kuwait: "965",
        Kyrgyzstan: "996",
        Laos: "856",
        Latvia: "371",
        Lebanon: "961",
        Lesotho: "266",
        Liberia: "231",
        Libya: "218",
        Liechtenstein: "423",
        Lithuania: "370",
        Luxembourg: "352",
        Macau: "853",
        Macedonia: "389",
        Madagascar: "261",
        Malawi: "265",
        Malaysia: "60",
        Maldives: "960",
        Mali: "223",
        Malta: "356",
        "Marshall Islands": "692",
        Mauritania: "222",
        Mauritius: "230",
        Mayotte: "262",
        Mexico: "52",
        Micronesia: "691",
        Moldova: "373",
        Monaco: "377",
        Mongolia: "976",
        Montenegro: "382",
        Morocco: "212",
        Mozambique: "258",
        Myanmar: "95",
        Namibia: "264",
        Nauru: "674",
        Nepal: "977",
        Netherlands: "31",
        "Netherlands Antilles": "599",
        "New Caledonia": "687",
        "New Zealand": "64",
        Nicaragua: "505",
        Niger: "227",
        Nigeria: "234",
        Niue: "683",
        "North Korea": "850",
        Norway: "47",
        Oman: "968",
        Pakistan: "92",
        Palau: "680",
        Palestine: "970",
        Panama: "507",
        "Papua New Guinea": "675",
        Paraguay: "595",
        Peru: "51",
        Philippines: "63",
        Pitcairn: "64",
        Poland: "48",
        Portugal: "351",
        Qatar: "974",
        "Republic of the Congo": "242",
        Reunion: "262",
        Romania: "40",
        Russia: "7",
        Rwanda: "250",
        "Saint Barthelemy": "590",
        "Saint Helena": "290",
        "Saint Martin": "590",
        "Saint Pierre and Miquelon": "508",
        Samoa: "685",
        "San Marino": "378",
        "Sao Tome and Principe": "239",
        "Saudi Arabia": "966",
        Senegal: "221",
        Serbia: "381",
        Seychelles: "248",
        "Sierra Leone": "232",
        Singapore: "65",
        Slovakia: "421",
        Slovenia: "386",
        "Solomon Islands": "677",
        Somalia: "252",
        "South Africa": "27",
        "South Korea": "82",
        "South Sudan": "211",
        Spain: "34",
        "Sri Lanka": "94",
        Sudan: "249",
        Suriname: "597",
        "Svalbard and Jan Mayen": "47",
        Swaziland: "268",
        Sweden: "46",
        Switzerland: "41",
        Syria: "963",
        Taiwan: "886",
        Tajikistan: "992",
        Tanzania: "255",
        Thailand: "66",
        Togo: "228",
        Tokelau: "690",
        Tonga: "676",
        Tunisia: "216",
        Turkey: "90",
        Turkmenistan: "993",
        Tuvalu: "688",
        Uganda: "256",
        Ukraine: "380",
        "United Arab Emirates": "971",
        "United Kingdom": "44",
        "United States": "1",
        Uruguay: "598",
        Uzbekistan: "998",
        Vanuatu: "678",
        Vatican: "379",
        Venezuela: "58",
        Vietnam: "84",
        "Wallis and Futuna": "681",
        "Western Sahara": "212",
        Yemen: "967",
        Zambia: "260",
        Zimbabwe: "263",
    };

    const { utm_source, utm_medium, utm_campaign } = queryString.parse(
        window.location.search
    );
    const renderCountryCodes = () => {
        return Object.keys(countryCodeList).map((val) => {
            return (
                <option
                    key={val}
                    value={countryCodeList[val]}
                    selected={countryCode === countryCodeList[val]}
                >
                    {countryCodeList[val]}
                </option>
            );
        });
    };
    const handleNameChange = (name) => {
        setName(name);
    };

    const sendSlackNotification = (message, callback) => {
        const webhook =
            "https://hooks.slack.com/services/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";
        const slack = new Slack();

        try {
            const slackValues = {
                channel: "landing-alerts",
                username: "landing-bot",
                icon_emoji: ":wave:",
                text: message,
            };

            slack.setWebhook(webhook);
            slack.webhook(
                slackValues,
                callback
                    ? () => {
                          callback();
                      }
                    : () => {}
            );
        } catch (err) {
            console.log("Could not send slack notification");
        }
    };

    const handleNextClick = () => {
        function isNumeric(value) {
            return /^-?\d+$/.test(value);
        }

        if (name === "" || mobileNumber === "") {
            setError("Please fill details above before proceeding");
        } else if (!isNumeric(mobileNumber) || mobileNumber.length < 10) {
            setError("Please enter a valid mobile number");
        } else {
            setSubmitted(1);
            const slackMsg = `A user submitted response with Name: ${name}, Phone Number: ${countryCode}${mobileNumber} on PCOS Landing.`;

            sendSlackNotification(slackMsg, (err) => {
                if (err) {
                    console.log("Slack error", err);
                } else {
                    const outputData = {
                        name,
                        mobileNumber: countryCode + mobileNumber,
                        utmSource: utm_source,
                        utmMedium: utm_medium,
                        utmCampaign: utm_campaign,
                    };

                    var options_trial_book = {
                        method: "POST",
                        url: "https://uvi-api.uvihealth.in/consult",
                        headers: {
                            "Content-Type": "application/x-www-form-urlencoded",
                        },
                        form: outputData,
                    };

                    request(
                        options_trial_book,
                        function (error, response, body) {
                            if (error) console.log(error);
                        }
                    );
                }
            });
        }
    };

    const returnForm = () => {
        return (
            <div>
                <div className="col-12">
                    <center>
                        <h2
                            className={styles.head2}
                            style={{
                                wordWrap: "break-word",
                            }}
                        >
                            Reserve Free Consultation
                        </h2>
                    </center>
                </div>

                <h2 className={styles.subhead2} style={{ textAlign: "center" }}>
                    Connect with our Health Coach to discuss case history and
                    PCOS treatment options
                </h2>

                <br />

                <div
                    className={classNames(
                        "form-group row mb-3",
                        styles.form_container
                    )}
                >
                    <div className="col-12">
                        <input
                            id="name"
                            autoComplete="off"
                            type="text"
                            className={""}
                            name="name"
                            placeholder="Name*"
                            onChange={(e) => {
                                handleNameChange(e.target.value);
                            }}
                            value={name}
                        />
                    </div>
                </div>

                <div
                    className={classNames(
                        "form-group row mb-3",
                        styles.form_container
                    )}
                >
                    <div
                        className="col-12"
                        style={{ display: "flex", gap: "20px" }}
                    >
                        <div className={styles.select_container}>
                            <select
                                onChange={(e) => setCountryCode(e.target.value)}
                            >
                                {renderCountryCodes()}
                            </select>
                        </div>

                        <input
                            autoComplete="off"
                            id="mobileNumber"
                            type="text"
                            className={""}
                            name="mobileNumber"
                            placeholder="Phone Number*"
                            onChange={(e) => {
                                setMobileNumber(e.target.value);
                            }}
                            value={mobileNumber}
                            maxLength="10"
                            pattern="\d{10}"
                        />
                    </div>
                </div>

                {error ? <h4 className="mcq-error-alert">{error}</h4> : null}

                <div>
                    <div className="row">
                        <div className="col-12">
                            <center>
                                <button
                                    className={classNames(styles.form_btn)}
                                    onClick={handleNextClick}
                                >
                                    Submit Details
                                </button>
                            </center>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const returnSuccess = () => {
        return (
            <div>
                <center>
                    <img
                        src="/assets/images/blue-tick.png"
                        alt="blue tick"
                        style={{
                            width: 75,
                            height: 75,
                        }}
                    />

                    <h4
                        style={{
                            fontSize: "1.2em",
                            color: "#4B4B6D",
                            fontWeight: 500,
                            marginTop: 20,
                            lineHeight: "1.3em",
                        }}
                    >
                        Thank you for submitting your details. One of our{" "}
                        <b>PCOS Expert</b> will reach out to you shortly.
                    </h4>
                </center>
            </div>
        );
    };

    return (
        <div className="uvi-landing-main" style={{ marginTop: "2em" }}>
            <hr />
            {submitted === 0 ? returnForm() : returnSuccess()}
            <hr />
        </div>
    );
};

export default UserForm;
