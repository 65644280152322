import React from "react";

const Media = () => {
    return (
        <div>
            <div className="row" style={{ marginTop: "5em" }}>
                <div className="col-12">
                    <center>
                        <h2
                            style={{
                                fontWeight: 500,
                                fontSize: "2em",
                                color: "#4B4B6D",
                                marginBottom: "2em",
                            }}
                        >
                            Simplifying hormonal health for women
                        </h2>
                    </center>
                </div>
            </div>

            <div className="row">
                <div
                    className="col-md-6 col-xs-12"
                    style={{
                        background: "rgba(255, 238, 234, 0.28)",
                        border: "2px solid #FFE6E1",
                        boxSizing: "border-box",
                        borderRadius: 14,
                        padding: 30,
                        marginTop: 10,
                        marginBottom: 10,
                    }}
                >
                    <div>
                        <center>
                            <img
                                src="/assets/images/media-1.png"
                                alt="pcos-4"
                                style={{ width: "80%" }}
                            />
                        </center>

                        <h4
                            style={{
                                color: "#4B4B6D",
                                fontWeight: 600,
                                marginTop: "3em",
                                marginBottom: "1em",
                            }}
                        >
                            Entrepreneur
                        </h4>

                        <h4
                            style={{
                                color: "#4B4B6D",
                                fontWeight: 400,
                                marginTop: "1em",
                                marginBottom: "1em",
                            }}
                        >
                            Uvi Health raises pre-seed round of funding
                        </h4>

                        <h4
                            style={{
                                color: "#969CBC",
                                fontWeight: 400,
                                marginTop: "1.2em",
                                marginBottom: "1.2em",
                            }}
                        >
                            July 19, 2021
                        </h4>

                        <h4
                            style={{
                                marginTop: "2em",
                                fontSize: "1.2em",
                                color: "#4B4B6D",
                                fontWeight: 400,
                                lineHeight: "1.3em",
                            }}
                        >
                            Digital health startup for women Uvi Health has
                            raised $330,000 in a pre-seed round of funding led
                            by Titan Capital, 9Unicorns, AngelList, and a clutch
                            of angels, including Ghazal Alagh, co-founder of
                            Mamaearth, Aarti Gill, and Mihir Gadani, founders of
                            Oziva.
                        </h4>
                    </div>
                </div>

                <div
                    className="col-md-6 col-xs-12"
                    style={{
                        background: "rgba(255, 238, 234, 0.28)",
                        border: "2px solid #FFE6E1",
                        boxSizing: "border-box",
                        borderRadius: 14,
                        padding: 30,
                        marginTop: 10,
                        marginBottom: 10,
                    }}
                >
                    <div>
                        <center>
                            <img
                                src="/assets/images/media-2.png"
                                alt="pcos-4"
                                style={{ width: "80%" }}
                            />
                        </center>

                        <h4
                            style={{
                                color: "#4B4B6D",
                                fontWeight: 600,
                                marginTop: "3em",
                                marginBottom: "1em",
                            }}
                        >
                            Economic Times
                        </h4>

                        <h4
                            style={{
                                color: "#4B4B6D",
                                fontWeight: 400,
                                marginTop: "1em",
                                marginBottom: "1em",
                            }}
                        >
                            Femtech startups want to change women's healthcare
                            in India
                        </h4>

                        <h4
                            style={{
                                color: "#969CBC",
                                fontWeight: 400,
                                marginTop: "1.2em",
                                marginBottom: "1.2em",
                            }}
                        >
                            Aug 21, 2021
                        </h4>

                        <h4
                            style={{
                                marginTop: "2em",
                                fontSize: "1.2em",
                                color: "#4B4B6D",
                                fontWeight: 400,
                                lineHeight: "1.3em",
                            }}
                        >
                            Danielle Ann Alves, a 32-year-old in Mumbai, had
                            been going to doctors for PCOS and thyroid since
                            2013. Finally, it was a customised fitness and meal
                            plan by Uvi Health, which she came across by chance
                            online, that helped her thyroid condition, she says.
                            “The trainers keep checking in on you. That helped,”
                            she says.
                        </h4>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Media;
