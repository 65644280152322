import React, { useState } from "react";

const TrialInfo = ({ handleChange }) => {
    const [name, setName] = useState("");
    const [age, setAge] = useState("");
    const [email, setEmail] = useState("");
    const [mobileNumber, setMobileNumber] = useState("");
    const [error, setError] = useState("");
    const [height, setHeight] = useState("");
    const [weight, setWeight] = useState("");
    const [city, setCity] = useState("");
    const [history, setHistory] = useState("");
    const [session, setSession] = useState("PCOS Yoga");
    const [fitnessLevel, setFitnessLevel] = useState("Beginner");
    const [workouts, setWorkouts] = useState("-");

    const handleAgeChange = (age) => {
        setAge(age);
    };

    const handleNameChange = (name) => {
        setName(name);
    };

    const changeSession = (val) => {
        setSession(val.target.value);
    };

    const changeFitnessLevel = (val) => {
        setFitnessLevel(val.target.value);
    };

    const changeWorkouts = (val) => {
        setWorkouts(val.target.value);
    };

    const handleNextClick = () => {
        function isNumeric(value) {
            return /^-?\d+$/.test(value);
        }

        function validateEmail(email) {
            const re =
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return re.test(String(email).toLowerCase());
        }

        if (name === "" || mobileNumber === "" || email === "") {
            setError("Please fill details above before proceeding");
        } else if (!isNumeric(mobileNumber) || mobileNumber.length < 10) {
            setError("Please enter a valid mobile number");
        } else if (!validateEmail(email)) {
            setError("Please enter a valid email address");
        } else {
            const output = {
                name,
                age,
                email,
                mobileNumber,
                height,
                weight,
                city,
                history,
                session,
                fitnessLevel,
                workouts,
            };

            console.log(output);

            handleChange("info", { output });
        }
    };

    return (
        <div className="uvi-landing-main">
            <h2 className="landing-home-hero-1">
                Tell us a little bit about you.
            </h2>

            <h4 className="landing-home-hero-2">&nbsp;</h4>

            <div className="form-group row mb-3">
                <div className="col-12">
                    <label htmlFor="name" className="form-label">
                        Name
                    </label>
                    <input
                        id="name"
                        autoComplete="off"
                        type="text"
                        className="form-control landing-page-form-element"
                        name="name"
                        placeholder="Type in your name here"
                        onChange={(e) => {
                            handleNameChange(e.target.value);
                        }}
                        value={name}
                    />
                </div>
            </div>

            {/* <div className="form-group row mb-3">
                <div className="col-12">
                    <label htmlFor="age" className="form-label">
                        Age
                    </label>
                    <input
                        autoComplete="off"
                        id="age"
                        type="text"
                        className="form-control landing-page-form-element"
                        name="name"
                        placeholder="How old are you?"
                        onChange={(e) => {
                            handleAgeChange(e.target.value);
                        }}
                        value={age}
                    />
                </div>
            </div> */}

            <div className="form-group row mb-3">
                <div className="col-12">
                    <label htmlFor="email" className="form-label">
                        Email
                    </label>
                    <input
                        autoComplete="off"
                        id="email"
                        type="email"
                        className="form-control landing-page-form-element"
                        name="email"
                        placeholder="Type in your email"
                        onChange={(e) => {
                            setEmail(e.target.value);
                        }}
                        value={email}
                    />
                </div>
            </div>

            <div className="form-group row mb-3">
                <div className="col-12">
                    <label htmlFor="mobileNumber" className="form-label">
                        WhatsApp Number
                    </label>
                    <input
                        autoComplete="off"
                        id="mobileNumber"
                        type="text"
                        className="form-control"
                        name="mobileNumber"
                        placeholder="Your 10 digit mobile number"
                        onChange={(e) => {
                            setMobileNumber(e.target.value);
                        }}
                        value={mobileNumber}
                        maxLength="10"
                        pattern="\d{10}"
                    />

                    <p style={{ marginTop: 10 }}>
                        Your Zoom session link details will be sent over
                        WhatsApp
                    </p>
                </div>
            </div>

            <div className="form-group row mb-3">
                <div className="col-12">
                    <label htmlFor="session" className="form-label">
                        Which yoga session are you interested in?
                    </label>
                    <select
                        className="form-control form-select"
                        id="session"
                        onChange={changeSession}
                        value={session}
                    >
                        <option
                            value="PCOS Yoga"
                            selected={session === "PCOS Yoga"}
                        >
                            PCOS Yoga
                        </option>
                        <option
                            value="Thyroid Yoga"
                            selected={session === "Thyroid Yoga"}
                        >
                            Thyroid Yoga
                        </option>
                        <option
                            value="Pre-natal Yoga"
                            selected={session === "Pre-natal Yoga"}
                        >
                            Pre-natal Yoga
                        </option>
                        <option
                            value="Post-natal Yoga"
                            selected={session === "Post-natal Yoga"}
                        >
                            Post-natal Yoga
                        </option>
                    </select>
                </div>
            </div>

            {/* <div className="form-group row mb-3">
                <div className="col-12">
                    <label htmlFor="height" className="form-label">
                        Height
                    </label>
                    <input
                        autoComplete="off"
                        id="height"
                        type="text"
                        className="form-control landing-page-form-element"
                        name="name"
                        placeholder="Enter your height in cms"
                        onChange={(e) => {
                            setHeight(e.target.value);
                        }}
                        value={height}
                    />
                </div>
            </div> */}

            {/* <div className="form-group row mb-3">
                <div className="col-12">
                    <label htmlFor="weight" className="form-label">
                        Weight
                    </label>
                    <input
                        autoComplete="off"
                        id="weight"
                        type="text"
                        className="form-control landing-page-form-element"
                        name="name"
                        placeholder="Enter your weight in kgs"
                        onChange={(e) => {
                            setWeight(e.target.value);
                        }}
                        value={weight}
                    />
                </div>
            </div> */}

            {/* <div className="form-group row mb-3">
                <div className="col-12">
                    <label htmlFor="city" className="form-label">
                        City
                    </label>
                    <input
                        autoComplete="off"
                        id="city"
                        type="text"
                        className="form-control landing-page-form-element"
                        name="name"
                        placeholder="Which city are you currently based in?"
                        onChange={(e) => {
                            setCity(e.target.value);
                        }}
                        value={city}
                    />
                </div>
            </div> */}

            {/* <div className="form-group row mb-3">
                <div className="col-12">
                    <label htmlFor="fitness-level" className="form-label">
                        What is your fitness level?
                    </label>
                    <select
                        className="form-control form-select"
                        id="fitness-level"
                        onChange={changeFitnessLevel}
                        value={fitnessLevel}
                    >
                        <option
                            value="Beginner"
                            selected={session === "Beginner"}
                        >
                            Beginner
                        </option>
                        <option
                            value="Intermediate"
                            selected={session === "Intermediate"}
                        >
                            Intermediate
                        </option>
                        <option
                            value="Advanced"
                            selected={session === "Advanced"}
                        >
                            Advanced
                        </option>
                        <option
                            value="Extreme"
                            selected={session === "Extreme"}
                        >
                            Extreme
                        </option>
                    </select>
                </div>
            </div> */}

            {/* <div className="form-group row mb-3">
                <div className="col-12">
                    <label htmlFor="workouts" className="form-label">
                        What type of workouts have you done previously?
                    </label>
                    <select
                        id="workouts"
                        className="form-control"
                        onChange={changeWorkouts}
                        value={workouts}
                    >
                        <option value="-" selected={session === "-"}>
                            -
                        </option>
                        <option value="Dance" selected={session === "Dance"}>
                            Dance
                        </option>
                        <option value="Yoga" selected={session === "Yoga"}>
                            Yoga
                        </option>
                        <option
                            value="Running"
                            selected={session === "Running"}
                        >
                            Running
                        </option>
                        <option
                            value="Weight Training"
                            selected={session === "Weight Training"}
                        >
                            Weight Training
                        </option>
                        <option
                            value="Walking"
                            selected={session === "Walking"}
                        >
                            Walking
                        </option>
                        <option
                            value="Strength and Core"
                            selected={session === "Strength and Core"}
                        >
                            Strength and Core
                        </option>
                        <option value="Others" selected={session === "Others"}>
                            Others
                        </option>
                    </select>
                </div>
            </div> */}

            {/* <div className="form-group row mb-3">
                <div className="col-12">
                    <label htmlFor="history" className="form-label">
                        History
                    </label>
                    <textarea
                        autoComplete="off"
                        id="history"
                        type="text"
                        className="form-control landing-page-form-element"
                        name="name"
                        placeholder="Do you have any history of pains/ surgeries/ injuries?"
                        onChange={(e) => {
                            setHistory(e.target.value);
                        }}
                        value={history}
                        style={{ height: 100 }}
                    />
                </div>
            </div> */}

            {error ? <h4 className="mcq-error-alert">{error}</h4> : null}

            <div>
                <div className="row">
                    <div className="col-12">
                        <button
                            className="btn btn-primary quiz-next btn-block"
                            onClick={handleNextClick}
                        >
                            Select Slot
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TrialInfo;
