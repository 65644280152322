import React from "react";

import WhyChooseUs from "../ui-elements/why-choose-us";
import HowItWorks2 from "../ui-elements/how-it-works-2";
import LandingVideo from "../ui-elements/landing-video";
import Testimonials from "../ui-elements/testimonials-2";
import Stats from "../home/stats";
import WhatToExpect from "../ui-elements/what-to-expect";
import UviMedia from "../ui-elements/uvi-media";
import ConnectHealthCoach from "../ui-elements/connect-health-coach";

const TrialIntro = ({ handleChange }) => {
    const handleClick = () => {
        handleChange("hero", null);
    };

    return (
        <div className="uvi-landing-main">
            <h2 className="landing-home-hero-1">
                Powerful workouts designed for{" "}
                <span style={{ color: "#f5735a" }}>
                    <em>your needs</em>
                </span>
            </h2>
            <h4 className="landing-home-hero-2">
                Attend an online Fitness Session with Uvi via Zoom for only
                <span style={{ color: "#f5735a" }}> ₹49/-</span>
            </h4>
            <h4 className="quiz-home-hero-1">
                Join hundreds of women across US, UK and India in specialised
                online sessions designed for PCOS, Thyroid, Pre-natal and
                Post-natal care.
            </h4>
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    marginBottom: "2em",
                }}
            >
                <img
                    src="/assets/images/fitness-trial.png"
                    className="hero-img"
                    alt="uvi-hero"
                    style={{ height: "75%", width: "75%" }}
                />
            </div>
            <button
                className="btn btn-primary book-trial btn-block"
                onClick={handleClick}
            >
                Book Trial
            </button>

            <WhyChooseUs />
            <HowItWorks2 />
            <Testimonials />
            <UviMedia />

            <button
                className="btn btn-primary book-trial btn-block"
                onClick={handleClick}
            >
                Book Trial
            </button>
        </div>
    );
};

export default TrialIntro;
