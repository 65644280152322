import React, { useState, useEffect } from 'react';
import ReactGA from 'react-ga';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import classNames from 'classnames';
import LandingHero from './landing-hero';
import WholeBodyApproach from './whole-body-approach';
// import Calendly from "./calendly";
import UserForm from './user-form';
import Navbar from './navbar';
import SuccessStories from './success-stories';
import Experts from './experts';
import WhatToExpect from './what-to-expect';
import Media from './media';
import FAQ from './faq';
import styles from '../../styleModules/style.module.css';

const Home = () => {
    const userId = 'UVIQL' + String(Date.now());
    const [tracker, setTracker] = useState(0);
    const [isModalOpen, setIsModalOpen] = useState(false);

    // const { utm_source, utm_medium, utm_campaign } = queryString.parse(
    //     window.location.search
    // );

    const calWidget = () => {
        return (
            <div
                className={styles.cal_widgit}
                onClick={() => {
                    setIsModalOpen(true);
                }}
            >
                <span
                    style={{
                        marginRight: '5px',
                    }}
                ></span>
                Book Free Consult
            </div>
        );
    };

    useEffect(() => {
        if (tracker === 0) {
            ReactGA.initialize('UA-201300789-1', {
                standardImplementation: true,
                titleCase: false,
                gaOptions: {
                    name: 'landingtracker',
                    userId,
                    siteSpeedSampleRate: 100,
                },
            });
            setTracker(1);
        }
    }, [tracker, userId]);

    return (
        <div className="container" id="container">
            <Navbar />
            <LandingHero />
            <WholeBodyApproach />
            {/* <Calendly /> */}
            <UserForm />
            <SuccessStories />
            <Experts />
            <WhatToExpect />
            <Media />
            <FAQ />
            {calWidget()}
            {isModalOpen && (
                <div className={classNames(styles.modal_container)}>
                    <div
                        onClick={() => {
                            setIsModalOpen(false);
                        }}
                        className={styles.closeModal}
                    >
                        X
                    </div>
                    <div className={styles.modal}>
                        <iframe
                            src="https://uvihealth.in/health-coach"
                            className={styles.cal}
                            allowFullScreen={true}
                            loading={true}
                        />
                    </div>
                </div>
            )}
        </div>
    );
};

export default Home;
