import React, { useState } from "react";

import WhyChooseUs from "../ui-elements/why-choose-us";
import HowItWorks3 from "../ui-elements/how-it-works-3";
import LandingVideo from "../ui-elements/landing-video";
import Testimonials from "../ui-elements/testimonials-2";
import Stats from "../home/stats";
import WhatToExpect from "../ui-elements/what-to-expect";
import UviMedia from "../ui-elements/uvi-media";
import ConnectHealthCoach from "../ui-elements/connect-health-coach";

import Slack from "slack-node";

const TrialIntro = ({ handleChange }) => {
    const [name, setName] = useState("");
    const [age, setAge] = useState("");
    const [email, setEmail] = useState("");
    const [mobileNumber, setMobileNumber] = useState("");
    const [error, setError] = useState("");
    const [height, setHeight] = useState("");
    const [weight, setWeight] = useState("");
    const [city, setCity] = useState("");
    const [history, setHistory] = useState("");
    const [session, setSession] = useState("PCOS Yoga");
    const [fitnessLevel, setFitnessLevel] = useState("Beginner");
    const [workouts, setWorkouts] = useState("-");

    const handleNameChange = (name) => {
        setName(name);
    };

    const changeSession = (val) => {
        setSession(val.target.value);
    };

    const sendSlackNotification = (message, callback) => {
        const webhook =
            "https://hooks.slack.com/services/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";
        const slack = new Slack();

        try {
            const slackValues = {
                channel: "landing-alerts",
                username: "landing-bot",
                icon_emoji: ":wave:",
                text: message,
            };

            slack.setWebhook(webhook);
            slack.webhook(
                slackValues,
                callback
                    ? () => {
                          callback();
                      }
                    : () => {}
            );
        } catch (err) {
            console.log("Could not send slack notification");
        }
    };

    const handleNextClick = () => {
        function isNumeric(value) {
            return /^-?\d+$/.test(value);
        }

        if (name === "" || mobileNumber === "") {
            setError("Please fill details above before proceeding");
        } else if (!isNumeric(mobileNumber) || mobileNumber.length < 10) {
            setError("Please enter a valid mobile number");
        } else {
            const output = {
                name,
                age,
                email,
                mobileNumber,
                height,
                weight,
                city,
                history,
                session,
                fitnessLevel,
                workouts,
            };

            const slackMsg = `A user submitted response with Name: ${name}, Phone Number: ${mobileNumber}, Session: ${session} on Free Fitness Trial Landing. Trial booking is pending.`;

            sendSlackNotification(slackMsg, (err) => {
                if (err) {
                    console.log("Slack error", err);
                } else {
                    handleChange("info", { output });
                }
            });
        }
    };

    return (
        <div className="uvi-landing-main">
            <h2 className="landing-home-hero-1">
                Powerful workouts designed for{" "}
                <span style={{ color: "#f5735a" }}>
                    <em>your needs</em>
                </span>
            </h2>
            <h4 className="landing-home-hero-2">
                Attend a{" "}
                <span style={{ color: "#f5735a" }}>
                    <em>free online Fitness Session</em>
                </span>{" "}
                with Uvi via Zoom
            </h4>
            <h4 className="quiz-home-hero-1">
                Join hundreds of women across US, UK and India in specialised
                online sessions designed for PCOS, Thyroid, Pre-natal and
                Post-natal care.
            </h4>
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    marginBottom: "2em",
                }}
            >
                <img
                    src="/assets/images/fitness-trial.png"
                    className="hero-img"
                    alt="uvi-hero"
                    style={{ height: "75%", width: "75%" }}
                />
            </div>

            <div
                className="pcos-plan-card"
                style={{ padding: 30, marginTop: 50 }}
            >
                <div className="form-group row mb-3">
                    <div className="col-12">
                        <label htmlFor="name" className="form-label">
                            Name
                        </label>
                        <input
                            id="name"
                            autoComplete="off"
                            type="text"
                            className="form-control landing-page-form-element"
                            name="name"
                            placeholder="Type in your name here"
                            onChange={(e) => {
                                handleNameChange(e.target.value);
                            }}
                            value={name}
                        />
                    </div>
                </div>

                <div className="form-group row mb-3">
                    <div className="col-12">
                        <label htmlFor="mobileNumber" className="form-label">
                            WhatsApp Number
                        </label>
                        <input
                            autoComplete="off"
                            id="mobileNumber"
                            type="text"
                            className="form-control"
                            name="mobileNumber"
                            placeholder="Your 10 digit mobile number"
                            onChange={(e) => {
                                setMobileNumber(e.target.value);
                            }}
                            value={mobileNumber}
                            maxLength="10"
                            pattern="\d{10}"
                        />

                        <p style={{ marginTop: 10 }}>
                            Your Zoom session link details will be sent over
                            WhatsApp
                        </p>
                    </div>
                </div>

                <div className="form-group row mb-3">
                    <div className="col-12">
                        <label htmlFor="session" className="form-label">
                            Which yoga session are you interested in?
                        </label>
                        <select
                            className="form-control form-select"
                            id="session"
                            onChange={changeSession}
                            value={session}
                        >
                            <option
                                value="PCOS Yoga"
                                selected={session === "PCOS Yoga"}
                            >
                                PCOS Yoga
                            </option>
                            <option
                                value="Thyroid Yoga"
                                selected={session === "Thyroid Yoga"}
                            >
                                Thyroid Yoga
                            </option>
                            <option
                                value="Pre-natal Yoga"
                                selected={session === "Pre-natal Yoga"}
                            >
                                Pre-natal Yoga
                            </option>
                            <option
                                value="Post-natal Yoga"
                                selected={session === "Post-natal Yoga"}
                            >
                                Post-natal Yoga
                            </option>
                        </select>
                    </div>
                </div>

                {error ? <h4 className="mcq-error-alert">{error}</h4> : null}

                <div>
                    <div className="row">
                        <div className="col-12">
                            <button
                                className="btn btn-primary book-trial btn-block"
                                onClick={handleNextClick}
                            >
                                Book Free Trial
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <WhyChooseUs />
            <HowItWorks3 />
            <Testimonials />
            <UviMedia />

            <div>
                <div className="row">
                    <div className="col-12">
                        <a
                            className="btn btn-primary book-trial btn-block"
                            href="https://uvihealth.in"
                        >
                            Explore Uvi Health
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TrialIntro;
