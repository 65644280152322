import React, { useEffect } from "react";

const FreeTrialAccess = ({ handleQuizSwitch }) => {
    useEffect(() => {
        setTimeout(() => {
            handleQuizSwitch();
        }, 1);
    });

    return (
        <div className="free-trial">
            <h2 className="free-trial-hero-text">
                Giving you access to the free trial
            </h2>

            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    marginBottom: "2em",
                }}
            >
                <img
                    src="/assets/images/uvi-animation.gif"
                    className="free-trial-hero-img"
                    alt="hero-uvi"
                />
            </div>
        </div>
    );
};

export default FreeTrialAccess;
