import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import Home from "./Components/home";
import Report from "./Components/home/report";
import Trial from "./Components/trial";
import FreeTrial from "./Components/free-trial";
import PCOSLanding from "./Components/pcos-landing";
import PCOSLanding2 from "./Components/health-coach-consult";

function App() {
    return (
        <>
            <div className="content-page">
                <Router>
                    <Switch>
                        <Route path="/fitness-trial" component={Trial} />
                        <Route
                            path="/free-fitness-trial"
                            component={FreeTrial}
                        />
                        <Route
                            path="/healthcoachconsult1"
                            component={PCOSLanding}
                        />
                        <Route
                            path="/healthcoachconsult2"
                            component={PCOSLanding2}
                        />
                        <Route path="/rootcause" component={Home} />
                        <Route path="/report/:id" component={Report} />
                        <Route path="/home" component={Home} />
                        <Route path="/" exact component={Home} />
                    </Switch>
                </Router>
            </div>
        </>
    );
}

export default App;
