import React from "react";

const Stats = ({ handleTrialSwitch }) => {
    const handleTrialClick = () => {
        handleTrialSwitch();
    };

    return (
        <div className="stats">
            <h2 className="landing-home-hero-1-1">
                Uvi's support system is loved by users
            </h2>

            <div className="row">
                <div className="col-12">
                    <p className="stats-number">91%</p>
                    <p className="stats-header">
                        women reported improvement in PCOS symptoms
                    </p>
                </div>

                <div className="col-12">
                    <p className="stats-number">10/10</p>
                    <p className="stats-header">
                        women recommend our Health Coach to a friend
                    </p>
                </div>

                <div className="col-12">
                    <p className="stats-number">9/10</p>
                    <p className="stats-header">
                        women recommend our Fitness Sessions to a friend
                    </p>
                </div>
            </div>
        </div>
    );
};

export default Stats;
