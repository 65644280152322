import React from 'react';

const LandingHero = ({ handleQuizSwitch }) => {
    const handleQuizClick = () => {
        handleQuizSwitch();
    };

    return (
        <div className="uvi-landing-main">
            <h2 className="landing-home-hero-1">Hi there! 👋</h2>

            <h4 className="landing-home-hero-2">
                We believe no two women are the same when it comes to PCOS. That is why we have designed a PCOS plan
                mapped to your root cause.
            </h4>

            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    marginBottom: '2em',
                }}
            ></div>

            <div className="uvi-card" id="form-cta">
                <div className="flex" style={{ alignSelf: 'center' }}>
                    <img src="/assets/images/hero-image.jpeg" className="hero-img-1" alt="hero-uvi" />
                </div>
                <h4 className="landing-home-hero-7">No more trial and error</h4>
                <h4 className="landing-home-hero-8">Unlock your exclusive access to Uvi Health's plan</h4>
                <h4 className="landing-home-hero-8">which includes...</h4>

                <div className="checklist-form">
                    <p>
                        <span className="material-icons landing-icon">check_circle_outline</span>
                        <span className="landing-list-text">PCOS Root Cause Analysis</span>
                    </p>
                    <p>
                        <span className="material-icons landing-icon">check_circle_outline</span>
                        <span className="landing-list-text">Live Fitness Sessions</span>
                    </p>
                    <p>
                        <span className="material-icons landing-icon">check_circle_outline</span>
                        <span className="landing-list-text">India's Top PCOS Expert Consultations</span>
                    </p>
                    <p>
                        <span className="material-icons landing-icon">check_circle_outline</span>
                        <span className="landing-list-text">Nutrition Support and Meal Tracking</span>
                    </p>
                </div>

                <hr />

                <button className="btn btn-primary landing-page-submit btn-block" onClick={() => handleQuizClick()}>
                    <span className="landing-submit-text">Take Root Cause Quiz</span>
                </button>
            </div>
        </div>
    );
};

export default LandingHero;
