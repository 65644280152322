import React from "react";

const Testimonials = () => {
    const reviews = [
        {
            program: "PCOS Plan Subscriber",
            description:
                "I have observed improvement in my body digestion, stamina within 2 months. This is the reason I was able to conceive easily.",
            name: "Sravani, Hyderabad",
            dp: "https://i.ibb.co/c66qjht/sravani-hyd.png",
        },
        {
            program: "PCOS Plan Subscriber",
            description:
                "As a working woman tackling PCOS has been a challenge for me, Uvi Health’s overall support system easily fits in my busy routine.",
            name: "Sharanya, Bangalore",
            dp: "https://i.ibb.co/fSTTB5B/sharanya-blr.png",
        },
        {
            program: "Thyroid Plan Subscriber",
            description:
                "I lost 2 Kgs in a month. I also feel more energetic and do not need an afternoon nap anymore.",
            name: "Neha Kulkarni, Nasik",
            dp: "https://i.ibb.co/ZHcZXgD/neha-kulkarni.png",
        },
        {
            program: "Pre-natal Plan Subscriber",
            description:
                "Uvi’s instructors are very articulate and give personal attention. Must join for prenatal yoga.",
            name: "Kanak, Jaipur",
            dp: null,
        },
        {
            program: "Post-natal Plan Subscriber",
            description:
                "After having a baby it becomes more difficult to work out, but my Instructor is very calm and has guided me so patiently through the whole experience.",
            name: "Garima Bhandari, Delhi",
            dp: "https://i.ibb.co/cghzgJ0/garima-bhandari.jpg",
        },
    ];

    const returnTestimonials = () => {
        return reviews.map((val) => {
            return (
                <div className="reviews-card" key={Math.random()}>
                    {val.dp ? (
                        <img
                            src={val.dp}
                            className="testimonial-dp"
                            alt="uvi-testimonial-dp"
                        />
                    ) : (
                        <div className="empty-dp" />
                    )}
                    <p className="testimonial-header">{val.name}</p>
                    <p className="testimonial-body">{val.description}</p>
                    <p className="testimonial-footer">{val.program}</p>
                </div>
            );
        });
    };

    return (
        <div className="testimonial">
            <h2 className="landing-home-hero-1-1">
                Read stories of other women →
            </h2>

            <div className="horizontal-row">{returnTestimonials()}</div>
        </div>
    );
};

export default Testimonials;
