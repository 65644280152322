import React, { useState, useEffect } from 'react';
import 'react-circular-progressbar/dist/styles.css';

import Stats from '../home/stats';
import LandingVideo from '../ui-elements/landing-video';
import Testimonials from '../ui-elements/testimonials';
import WhatToExpect from '../ui-elements/what-to-expect';

const QuizFinalResult = ({
    name,
    scores,
    quizScore,
    quizTotalScore,
    conceive,
    age,
    height,
    weight,
    diagnosisTime,
    mainSymptoms,
}) => {
    const [plan, setPlan] = useState('');
    const downloadMode = 0;

    const getLevel = (scope) => {
        if (scope === 'cis') {
            return scores.cisScore <= 28 ? 'Mild' : scores.cisScore <= 67 ? 'Moderate' : 'Severe';
        } else if (scope === 'irs') {
            return scores.irsScore <= 30 ? 'Mild' : scores.irsScore <= 60 ? 'Moderate' : 'Severe';
        } else if (scope === 'ass') {
            return scores.assScore <= 34 ? 'Mild' : scores.assScore <= 67 ? 'Moderate' : 'Severe';
        } else if (scope === 'aes') {
            return scores.aesScore <= 34 ? 'Mild' : scores.aesScore <= 67 ? 'Moderate' : 'Severe';
        }
    };

    const returnColor = (severity) => {
        if (severity === 'Mild') {
            return '#969CBC';
        } else if (severity === 'Moderate') {
            return '#F8CA27';
        } else if (severity === 'Severe') {
            return '#F5735A';
        }
    };

    const conditionDetails = {
        cis: {
            header: 'Chronic Inflammation',
            first: 'Chronic Inflammation is one of the markers of PCOS. It can be triggered by your diet or environment and can worsen the hormonal balance in your body.',
            second: '💡 Introduction of anti-inflammatory foods, and fitness routines are proven to lower chronic inflammation in women with PCOS.',
        },
        irs: {
            header: 'Insulin Resistance',
            first: 'Having Insulin Resistance means that your body is not doing a good job of processing glucose, the energy source for your body. High insulin can impair ovulation and lead to irregular periods.',
            second: '💡 A right diet and nutrition routine can help keep your insulin and blood sugars in check naturally.',
        },
        ass: {
            header: 'Adrenals and Stress',
            first: 'Adrenal hormones are responsible for regulation of metabolism, immune system and response to stress. 40% of women with PCOS produce excess adrenals.',
            second: '💡 Deep breathing, yoga and stress reduction techniques help in managing this root cause.',
        },
        aes: {
            header: 'Androgen Excess',
            first: 'Excess production of androgen, also referred to as “male hormone”, can contribute to abnormal body hair, acne, increase in weight and hair loss.',
            second: '💡 Depending on the severity of excess androgens in your body, this root cause can be managed with the right diet & anti-androgen medications.',
        },
    };

    useEffect(() => {
        if (conceive === 1) {
            setPlan('fertility');
        } else {
            const planLevel = (quizScore / quizTotalScore) * 100;

            if (planLevel > 25) {
                setPlan('advanced');
            } else {
                setPlan('basic');
            }
        }
    }, [quizScore, quizTotalScore, conceive]);

    const planDetails = {
        basic: {
            header: "Beginner's Plan",
            details: 'perfect when you want to start losing weight with PCOS',
            link: 'https://rzp.io/l/uvipcos1month',
        },
        advanced: {
            header: 'Advanced Plan',
            details: 'perfect when you want to conquer all PCOS symptoms holistically',
            link: 'https://rzp.io/l/uvipcos3month',
        },
        fertility: {
            header: 'Fertility Plan',
            details: 'perfect when you want to conceive with PCOS with specialized support',
            link: 'https://rzp.io/l/uvipcos6month',
        },
    };

    const returnCard = (type, score) => {
        return (
            <div className="col-12">
                <div className="final-result-card">
                    <h3 className="final-result-card-header">{conditionDetails[type].header}</h3>
                    <h3
                        className="final-result-card-score"
                        style={{ color: returnColor(getLevel(type)) }}
                    >{`${score.toFixed(0)}%`}</h3>
                    <h3 className="final-result-card-level">{getLevel(type)}</h3>
                </div>
            </div>
        );
    };

    return (
        <div className="uvi-landing-main">
            <h2 className="quiz-report-hero-1">
                {`${name}'s`} <br /> PCOS Root Cause Analysis
            </h2>

            <h4 className="quiz-report-hero-2">Key Insights</h4>

            <div className="row quiz-report-user-stats">
                <div className="col-4 quiz-report-user-stats-section-1">
                    <div className="quiz-report-user-stats-number">{age}</div>
                    <div className="quiz-report-user-stats-text">years</div>
                </div>
                <div className="col-4 quiz-report-user-stats-section-2">
                    <div className="quiz-report-user-stats-number">{height}</div>
                    <div className="quiz-report-user-stats-text">cms</div>
                </div>
                <div className="col-4 quiz-report-user-stats-section-3">
                    <div className="quiz-report-user-stats-number">{weight}</div>
                    <div className="quiz-report-user-stats-text">kgs</div>
                </div>

                <div className="col-12">
                    <h4 className="quiz-report-diagnosis-time">{`Diagnosed ${diagnosisTime}`}</h4>

                    <h4 className="quiz-report-diagnosis-time">{`Symptoms: ${mainSymptoms.split(',').join(', ')}`}</h4>
                </div>
            </div>

            <div className="row">
                {returnCard('cis', scores.cisScore)}
                {returnCard('irs', scores.irsScore)}
                {returnCard('ass', scores.assScore)}
                {returnCard('aes', scores.aesScore)}

                <div className="col-12 disclaimer-section">
                    <p className="disclaimer-text">
                        Disclaimer: Your root cause analysis is meant to be a guidance tool and should not be trated as
                        an alternative to PCOS diagnosis or consult with a licensed medical provider.
                    </p>
                </div>

                <div className="col-12">
                    <p className="final-result-contact">
                        Call{' '}
                        <span
                            style={{
                                color: '#f5735a',
                            }}
                        >
                            080-4568-5333
                        </span>{' '}
                        for more information
                    </p>
                </div>
            </div>

            {downloadMode === 0 ? (
                <div>
                    <div className="row">
                        <p className="final-result-talk">
                            Link to your full report has been sent your WhatsApp. Discuss your results with a Health
                            Coach.
                        </p>
                        <a
                            className="btn btn-primary talk-to-health-coach btn-block"
                            href="https://uvihealth.in/health-coach"
                        >
                            Talk to a Health Coach
                        </a>
                    </div>
                    <div className="final-results-plan">
                        <div className="final-results-plan-card">
                            <h4 className="final-results-plan-header">
                                We highly recommend that you subscribe to one of our Care plans
                            </h4>

                            <a className="btn btn-primary quiz-forward btn-block" href="https://uvihealth.in/pcos-plan">
                                Explore Plans
                            </a>
                        </div>
                    </div>

                    <Stats />
                    <LandingVideo />
                    <Testimonials />
                    <WhatToExpect />

                    <div className="final-results-footer">
                        <h4 className="final-results-footer-text">
                            Have any more questions about our PCOS plan? Go ahead & book a call with us, we’ll be more
                            than happy to help you out.
                        </h4>

                        <a
                            className="btn btn-primary talk-to-health-coach btn-block"
                            href="https://uvihealth.in/health-coach"
                        >
                            Talk to a Health Coach
                        </a>
                    </div>
                </div>
            ) : null}
        </div>
    );
};

export default QuizFinalResult;
