import React from "react";

const HowItWorks = () => {
    return (
        <div className="how-it-works">
            <h2 className="landing-home-hero-1-1">How it works</h2>

            <div className="row">
                <div className="col-12 how-it-works-card">
                    <div className="row">
                        <div className="col-3">
                            <img
                                src="/assets/images/number-one.svg"
                                className="how-it-works-img"
                                alt="how-it-works-one"
                            />
                        </div>
                        <div className="col-9">
                            <p className="how-it-works-header">
                                Meet your Health Coach
                            </p>
                            <p className="how-it-works-body">
                                Talk to our team about your PCOS history and
                                symptoms
                            </p>
                        </div>
                    </div>
                </div>

                <div className="col-12 how-it-works-card">
                    <div className="row">
                        <div className="col-3">
                            <img
                                src="/assets/images/number-two.svg"
                                className="how-it-works-img"
                                alt="how-it-works-two"
                            />
                        </div>
                        <div className="col-9">
                            <p className="how-it-works-header">
                                Find your root cause
                            </p>
                            <p className="how-it-works-body">
                                Share your lab work & take our assessment to
                                learn about your root cause
                            </p>
                        </div>
                    </div>
                </div>

                <div className="col-12 how-it-works-card">
                    <div className="row">
                        <div className="col-3">
                            <img
                                src="/assets/images/number-three.svg"
                                className="how-it-works-img"
                                alt="how-it-works-three"
                            />
                        </div>
                        <div className="col-9">
                            <p className="how-it-works-header">
                                Start your plan
                            </p>
                            <p className="how-it-works-body">
                                Begin your 1:1 consults with our experts + live
                                fitness coaching. Weekly follow-ups.
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default HowItWorks;
