import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import request from 'request';
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

import Stats from './stats';
import LandingVideo from '../ui-elements/landing-video';
import Testimonials from '../ui-elements/testimonials';
import WhatToExpect from '../ui-elements/what-to-expect';

const Report = () => {
    const [plan, setPlan] = useState('');
    const [dataReceived, setDataReceived] = useState(0);
    const { id } = useParams();

    const [name, setName] = useState('');
    const [age, setAge] = useState(0);
    const [height, setHeight] = useState(0);
    const [weight, setWeight] = useState(0);
    const [diagnosisTime, setDiagnosisTime] = useState('');
    const [mainSymptoms, setMainSymptoms] = useState('');
    const [cisScore, setCISScore] = useState(0);
    const [irsScore, setIRSScore] = useState(0);
    const [assScore, setASSScore] = useState(0);
    const [aesScore, setAESScore] = useState(0);
    const [conceive, setConceive] = useState(0);

    useEffect(() => {
        var options = {
            method: 'GET',
            url: `https://api.airtable.com/v0/appwFxqOd7nt23PIX/QuizResponses?api_key=keyXmd0WFnyt0MA48&filterByFormula={ID}="${id}"`,
        };

        let dataMatch = false;

        if (dataReceived === 0) {
            request(options, (error, response, body) => {
                if (error) {
                    setDataReceived(2);
                } else if (body) {
                    try {
                        const apiData = JSON.parse(body);
                        if (apiData.records.length > 0) {
                            apiData.records.forEach((val) => {
                                if (val.fields.ID === id) {
                                    dataMatch = true;
                                    setDataReceived(1);
                                    setName(val.fields['Name']);
                                    setConceive(val.fields['Planning to Conceive'] === 'Yes' ? 1 : 0);
                                    setAge(val.fields['Age']);
                                    setHeight(val.fields['Height']);
                                    setWeight(val.fields['Weight']);
                                    setDiagnosisTime(val.fields['Diagnosis Time']);
                                    setMainSymptoms(val.fields['Main Symptoms']);
                                    setCISScore(val.fields['CIS Score']);
                                    setIRSScore(val.fields['IRS Score']);
                                    setASSScore(val.fields['ASS Score']);
                                    setAESScore(val.fields['AES Score']);
                                }
                            });

                            if (!dataMatch) {
                                setDataReceived(2);
                            }
                        } else {
                            setDataReceived(2);
                        }
                    } catch (error) {
                        setDataReceived(2);
                    }
                }
            });
        }
    }, [dataReceived, id]);

    const getLevel = (scope) => {
        if (scope === 'cis') {
            return cisScore <= 28 ? 'Mild' : cisScore <= 67 ? 'Moderate' : 'Severe';
        } else if (scope === 'irs') {
            return irsScore <= 30 ? 'Mild' : irsScore <= 60 ? 'Moderate' : 'Severe';
        } else if (scope === 'ass') {
            return assScore <= 34 ? 'Mild' : assScore <= 67 ? 'Moderate' : 'Severe';
        } else if (scope === 'aes') {
            return aesScore <= 34 ? 'Mild' : aesScore <= 67 ? 'Moderate' : 'Severe';
        }
    };

    const returnColor = (severity) => {
        if (severity === 'Mild') {
            return '#969CBC';
        } else if (severity === 'Moderate') {
            return '#F8CA27';
        } else if (severity === 'Severe') {
            return '#F5735A';
        }
    };

    const conditionDetails = {
        cis: {
            header: 'Chronic Inflammation',
            first: 'Chronic Inflammation is one of the markers of PCOS. It can be triggered by your diet or environment and can worsen the hormonal balance in your body.',
            second: '💡 Introduction of anti-inflammatory foods, and fitness routines are proven to lower chronic inflammation in women with PCOS.',
        },
        irs: {
            header: 'Insulin Resistance',
            first: 'Having Insulin Resistance means that your body is not doing a good job of processing glucose, the energy source for your body. High insulin can impair ovulation and lead to irregular periods.',
            second: '💡 A right diet and nutrition routine can help keep your insulin and blood sugars in check naturally.',
        },
        ass: {
            header: 'Adrenals and Stress',
            first: 'Adrenal hormones are responsible for regulation of metabolism, immune system and response to stress. 40% of women with PCOS produce excess adrenals.',
            second: '💡 Deep breathing, yoga and stress reduction techniques help in managing this root cause.',
        },
        aes: {
            header: 'Androgen Excess',
            first: 'Excess production of androgen, also referred to as “male hormone”, can contribute to abnormal body hair, acne, increase in weight and hair loss.',
            second: '💡 Depending on the severity of excess androgens in your body, this root cause can be managed with the right diet & anti-androgen medications.',
        },
    };

    useEffect(() => {
        if (conceive === 1) {
            setPlan('fertility');
        } else {
            const planLevel =
                (parseFloat(cisScore) + parseFloat(irsScore) + parseFloat(assScore) + parseFloat(aesScore)) / 4;

            if (planLevel > 25) {
                setPlan('advanced');
            } else {
                setPlan('basic');
            }
        }
    }, [conceive, cisScore, irsScore, assScore, aesScore]);

    const planDetails = {
        basic: {
            header: "Beginner's Plan",
            details: 'perfect when you want to start losing weight with PCOS',
            link: 'https://rzp.io/l/uvipcos1month',
        },
        advanced: {
            header: 'Advanced Plan',
            details: 'perfect when you want to conquer all PCOS symptoms holistically',
            link: 'https://rzp.io/l/uvipcos3month',
        },
        fertility: {
            header: 'Fertility Plan',
            details: 'perfect when you want to conceive with PCOS with specialized support',
            link: 'https://rzp.io/l/uvipcos6month',
        },
    };

    const returnCard = (type, score) => {
        return (
            <div className="col-12">
                <div className="report-section-card">
                    <div className="row">
                        <div
                            className="col-4"
                            style={{
                                display: 'flex',
                                justifyContent: 'center',
                                flexDirection: 'column',
                            }}
                        >
                            <CircularProgressbar
                                styles={buildStyles({
                                    textColor: '#4B4B6D',
                                    pathColor: returnColor(getLevel(type)),
                                    trailColor: '#d6d6d6',
                                    backgroundColor: returnColor(getLevel(type)),
                                })}
                                value={score}
                                text={`${score.toFixed(0)}%`}
                            />
                            <h4 className="report-section-legend">{getLevel(type)}</h4>
                        </div>
                        <div className="col-8">
                            <h3 className="report-section-header">{conditionDetails[type].header}</h3>
                            <h3 className="report-section-body">{conditionDetails[type].first}</h3>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-12">
                            <div className="report-section-secondary-card">
                                <div className="report-section-secondary-text">{conditionDetails[type].second}</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };

    const loadingReport = () => {
        return (
            <div style={{ marginTop: '30vh' }}>
                <h2 className="quiz-report-hero-1">Loading report ...</h2>
            </div>
        );
    };

    const returnNoReport = () => {
        return (
            <div style={{ marginTop: '20vh' }}>
                <div className="col-12">
                    <h2 className="quiz-report-hero-1">No Report Found</h2>

                    <a
                        className="btn btn-primary talk-to-health-coach"
                        href="https://uvihealth.in"
                        style={{
                            marginTop: '3em',
                            display: 'flex',
                            justifyContent: 'center',
                        }}
                    >
                        Visit Uvi Health
                    </a>
                </div>
            </div>
        );
    };

    return (
        <div className="uvi-landing-main">
            <div className="col-12">
                <img src="/assets/images/uvi-logo.svg" className="uvi-logo" alt="uvi-logo" />
            </div>

            {dataReceived === 0 ? (
                loadingReport()
            ) : dataReceived === 2 ? (
                returnNoReport()
            ) : (
                <div>
                    <h2 className="quiz-report-hero-1">
                        {`${name}'s`} <br /> PCOS Root Cause Analysis
                    </h2>
                    <h4 className="quiz-report-hero-2">Full Report</h4>

                    <div className="row quiz-report-user-stats">
                        <div className="col-4 quiz-report-user-stats-section-1">
                            <div className="quiz-report-user-stats-number">{age}</div>
                            <div className="quiz-report-user-stats-text">years</div>
                        </div>
                        <div className="col-4 quiz-report-user-stats-section-2">
                            <div className="quiz-report-user-stats-number">{height}</div>
                            <div className="quiz-report-user-stats-text">cms</div>
                        </div>
                        <div className="col-4 quiz-report-user-stats-section-3">
                            <div className="quiz-report-user-stats-number">{weight}</div>
                            <div className="quiz-report-user-stats-text">kgs</div>
                        </div>

                        <div className="col-12">
                            <h4 className="quiz-report-diagnosis-time">{`Diagnosed ${diagnosisTime}`}</h4>

                            <h4 className="quiz-report-diagnosis-time">
                                {`Symptoms: ${mainSymptoms.split(',').join(', ')}`}
                            </h4>
                        </div>
                    </div>

                    <div className="row">
                        {returnCard('cis', parseFloat(cisScore))}
                        {returnCard('irs', parseFloat(irsScore))}
                        {returnCard('ass', parseFloat(assScore))}
                        {returnCard('aes', parseFloat(aesScore))}

                        <div className="col-12 disclaimer-section">
                            <p className="disclaimer-text">
                                Disclaimer: Your root cause analysis is meant to be a guidance tool and should not be
                                trated as an alternative to PCOS diagnosis or consult with a licensed medical provider.
                            </p>
                        </div>

                        <div className="col-12">
                            <p className="final-result-contact">
                                Call{' '}
                                <span
                                    style={{
                                        color: '#f5735a',
                                    }}
                                >
                                    080-4568-5333
                                </span>{' '}
                                for more information
                            </p>
                        </div>
                    </div>
                    <div>
                        <div className="final-results-plan">
                            <h2 className="final-results-plan-hero">We highly recommend that you subscribe to</h2>

                            <div className="final-results-plan-card">
                                <h4 className="final-results-plan-header">
                                    {planDetails[plan] ? planDetails[plan].header : ''}
                                </h4>
                                <h4 className="final-results-plan-details">
                                    {planDetails[plan] ? planDetails[plan].details : ''}
                                </h4>

                                <a
                                    className="btn btn-primary quiz-forward btn-block"
                                    href={planDetails[plan] ? planDetails[plan].link : '#'}
                                >
                                    Subscribe
                                </a>
                            </div>
                        </div>

                        <Stats />
                        <LandingVideo />
                        <Testimonials />
                        <WhatToExpect />

                        <div className="final-results-footer">
                            <h4 className="final-results-footer-text">
                                Have any more questions about our PCOS plan? Go ahead & book a call with us, we’ll be
                                more than happy to help you out.
                            </h4>

                            <a
                                className="btn btn-primary talk-to-health-coach btn-block"
                                href="https://uvihealth.in/health-coach"
                            >
                                Talk to a Health Coach
                            </a>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default Report;
