import React, { useState } from "react";

const QuizConceive = ({ handleChange }) => {
    const [value, setValue] = useState("");
    const [error, setError] = useState(0);

    const setAnswer = (answer) => {
        setValue(answer === "Yes" ? 1 : answer === "No" ? 0 : "");
    };

    const handleNextClick = () => {
        if (value === "") {
            setError(1);
        } else {
            handleChange("conceive", { value, totalValue: 1 });
        }
    };

    return (
        <div className="uvi-landing-main">
            <div className="progress progress-md">
                <div
                    className="progress-bar"
                    role="progressbar"
                    style={{ width: "98%", backgroundColor: "#DC4A9A" }}
                    aria-valuenow="98"
                    aria-valuemin="0"
                    aria-valuemax="100"
                ></div>
            </div>
            <h2 className="landing-home-hero-1">
                Are you currently trying to conceive?
            </h2>

            <div className="row answer-section">
                <div className="col-12">
                    <div
                        className={
                            value === 1 ? "answer-card-selected" : "answer-card"
                        }
                        onClick={() => setAnswer("Yes")}
                    >
                        Yes
                    </div>
                </div>
                <div className="col-12">
                    <div
                        className={
                            value === 0 ? "answer-card-selected" : "answer-card"
                        }
                        onClick={() => setAnswer("No")}
                    >
                        No
                    </div>
                </div>
            </div>

            {error === 1 ? (
                <h4 className="mcq-error-alert">
                    Please select one of the options above
                </h4>
            ) : null}

            <div>
                <div className="row">
                    <div className="col-12">
                        <button
                            className="btn btn-primary quiz-next"
                            onClick={handleNextClick}
                        >
                            Next
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default QuizConceive;
