import React from "react";

const WhyChooseUs = () => {
    return (
        <div className="pcos-plan-details">
            <h3 className="landing-home-hero-7" style={{ fontSize: 22 }}>
                Why choose us?
            </h3>

            <div className="pcos-plan-card">
                <div className="row">
                    <div
                        className="col-3"
                        style={{ display: "flex", justifyContent: "center" }}
                    >
                        <img
                            src="/assets/images/yoga.png"
                            className="pcos-plan-img"
                            alt="pcos-plan-nutrition"
                        />
                    </div>
                    <div className="col-9">
                        <p className="pcos-plan-header">
                            Train with Certified Instructors
                        </p>
                        <p className="pcos-plan-body">
                            Small group sessions to enable interactive
                            experience
                        </p>
                    </div>
                </div>
            </div>

            <div className="pcos-plan-card">
                <div className="row">
                    <div
                        className="col-3"
                        style={{ display: "flex", justifyContent: "center" }}
                    >
                        <img
                            src="/assets/images/calendar.png"
                            className="pcos-plan-img"
                            alt="pcos-plan-fitness"
                        />
                    </div>
                    <div className="col-9">
                        <p className="pcos-plan-header">
                            Choose from 200+ live classes
                        </p>
                        <p className="pcos-plan-body">
                            Live Zoom workout sessions with flexible scheduling
                        </p>
                    </div>
                </div>
            </div>

            <div className="pcos-plan-card">
                <div className="row">
                    <div
                        className="col-3"
                        style={{ display: "flex", justifyContent: "center" }}
                    >
                        <img
                            src="/assets/images/growth.png"
                            className="pcos-plan-img"
                            alt="pcos-plan-consult"
                        />
                    </div>
                    <div className="col-9">
                        <p className="pcos-plan-header">
                            Basic to advanced levels covered
                        </p>
                        <p className="pcos-plan-body">
                            Join sessions that match your fitness intensity
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default WhyChooseUs;
