import React from "react";
import styles from "../../styleModules/style.module.css";

const WholeBodyApproach = () => {
    return (
        <div className="row" style={{ marginTop: "2em" }}>
            <div className="col-12">
                <center>
                    <h2 className={styles.head2}>
                        Uvi uses a{" "}
                        <span style={{ color: "#F5735A" }}>
                            whole-body healing
                        </span>{" "}
                        approach
                    </h2>
                </center>
            </div>
            <div className={styles.grid} style={{ marginTop: "1em" }}>
                <div className={styles.card_container}>
                    <center>
                        <img
                            src="/assets/images/pcos-landing-img-1.png"
                            alt="pcos-1"
                        />

                        <h3>Live & Interactive PCOS Fitness Sessions</h3>
                    </center>
                </div>

                <div>
                    <div className={styles.card_container}>
                        <center>
                            <img
                                src="/assets/images/pcos-landing-img-02.png"
                                alt="pcos-1"
                            />

                            <h3>Dedicated Diet Coach with 24*7 chat support</h3>
                        </center>
                    </div>
                </div>

                <div>
                    <div className={styles.card_container}>
                        <center>
                            <img
                                src="/assets/images/pcos-landing-img-3.png"
                                alt="pcos-1"
                            />

                            <h3>Consultations with India’s top PCOS experts</h3>
                        </center>
                    </div>
                </div>

                <div>
                    <div className={styles.card_container}>
                        <center>
                            <img
                                src="/assets/images/pcos-landing-img-9.png"
                                alt="pcos-1"
                            />

                            <h3>Access to webinars & Uvi support group</h3>
                        </center>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default WholeBodyApproach;
