import React from "react";

const WhatToExpect = () => {
    return (
        <div className="what-to-expect">
            <div className="what-to-expect-card">
                <div className="row">
                    <div className="col-12">
                        <p className="landing-home-hero-1-1-1">
                            What to expect ahead of the plan
                        </p>
                    </div>
                    <div className="col-12">
                        <p className="stats-number">Month 1</p>
                        <p className="stats-header">
                            Start losing weight, improve metabolic function,
                            improve fatigue, sleep
                        </p>
                    </div>

                    <div className="col-12">
                        <p className="stats-number">Month 3</p>
                        <p className="stats-header">
                            Lose weight, reduce hair loss & skin issues,
                            menstrual cycle starts regulating
                        </p>
                    </div>

                    <div className="col-12">
                        <p className="stats-number">Month 6</p>
                        <p className="stats-header">
                            Lose weight, regulate menstrual cycles, reversal of
                            PCOS symptoms, conceive with expert guidance
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default WhatToExpect;
