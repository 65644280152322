import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import request from 'request';
import ReactGA from 'react-ga';
import queryString from 'query-string';

import LandingHero from '../ui-elements/landing-hero';
import Testimonials from '../ui-elements/testimonials';
import PCOSPlan from '../ui-elements/pcos-plan-details';
import HowItWorks from '../ui-elements/how-it-works';
import LandingVideo from '../ui-elements/landing-video';
import Stats from './stats';
import WhatToExpect from '../ui-elements/what-to-expect';
import UviMedia from '../ui-elements/uvi-media';
import ConnectHealthCoach from '../ui-elements/connect-health-coach';
import FreeTrialAccess from '../ui-elements/free-trial-access';
import StartTrial from './start-trial';

import QuizHero from '../quiz/index';
import QuizAge from '../quiz/age';
import PCOSDiagnosis from '../quiz/pcos-diagnosis';
import DiagnosisTime from '../quiz/diagnosis-time';
import MainSymptoms from '../quiz/main-symptoms';
import QuizHeightWeight from '../quiz/height-weight';
import Questions1 from '../quiz/questions-1';
import QuizFriedFood from '../quiz/fried-food';
import QuizFoodAllergies from '../quiz/food-allergies';
import Questions2 from '../quiz/questions-2';
import Questions3 from '../quiz/questions-3';
import Questions4 from '../quiz/questions-4';
import Questions5 from '../quiz/questions-5';
import Questions6 from '../quiz/questions-6';
import QuizEatingDisorders from '../quiz/eating-disorders';
import Questions7 from '../quiz/questions-7';
import QuizConceive from '../quiz/conceive';
import QuizPreResult from '../quiz/pre-result';
import QuizFinalResult from '../quiz/final-result';
import NoPCOS from '../quiz/no-pcos';
import TrialBooking from './trial';
import DirectReport from '../ui-elements/direct_report';

const Home = () => {
    const userId = 'UVIQL' + String(Date.now());
    const [quizMode, setQuizMode] = useState(0);
    const [quizProgress, setQuizProgress] = useState(0);
    const [tracker, setTracker] = useState(0);
    const { utm_source, utm_medium, utm_campaign } = queryString.parse(window.location.search);

    const { mode } = queryString.parse(window.location.search);

    useEffect(() => {
        if (mode && mode === 'onboard') {
            setQuizMode(1);
            setQuizProgress(1);
        }

        if (mode && mode === 'direct') {
            setQuizMode(1);
            setQuizProgress(1);
        }
    }, [mode]);

    const [quizScore, setQuizScore] = useState(0);
    const [quizTotalScore, setQuizTotalScore] = useState(0);
    const [age, setAge] = useState(0);
    const [pcosDiagnosed, setPcosDiagnosed] = useState(0);
    const [height, setHeight] = useState(0);
    const [weight, setWeight] = useState(0);
    const [multiQuestions, setMultiQuestions] = useState([]);
    const [friedFoods, setFriedFoods] = useState(0);
    const [foodAllergies, setFoodAllergies] = useState(0);
    const [eatingDisorders, setEatingDisorders] = useState(0);
    const [conceive, setConceive] = useState(0);
    const [diagnosisTime, setDiagnosisTime] = useState(0);
    const [mainSymptoms, setMainSymptoms] = useState('');
    const [trialDate, setTrialDate] = useState('');
    const [trialType, setTrialType] = useState('');
    const [trialLink, setTrialLink] = useState('');

    const [cisScore, setCISScore] = useState(0);
    const [cisTotalScore, setCISTotalScore] = useState(0);
    const [irsScore, setIRSScore] = useState(0);
    const [irsTotalScore, setIRSTotalScore] = useState(0);
    const [assScore, setASSSCore] = useState(0);
    const [assTotalScore, setASSTotalScore] = useState(0);
    const [aesScore, setAESScore] = useState(0);
    const [aesTotalScore, setAESTotalScore] = useState(0);

    const [responses, setResponses] = useState([]);

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [mobileNumber, setMobileNumber] = useState('');

    useEffect(() => {
        if (tracker === 0) {
            ReactGA.initialize('UA-201300789-1', {
                standardImplementation: true,
                titleCase: false,
                gaOptions: {
                    name: 'landingtracker',
                    userId: 'UVIQL' + String(Date.now()),
                    siteSpeedSampleRate: 100,
                },
            });
            setTracker(1);
        }
    }, [tracker]);

    const updateQuizMode = () => {
        setQuizMode(1);
        window.fbq('track', 'SubmitApplication');
    };

    const updateFreeTrialMode = () => {
        setQuizMode(2);
    };

    const handleUserInfo = (email, mobileNumber) => {
        setEmail(email);
        setMobileNumber(mobileNumber);

        setQuizProgress(quizProgress + 2);
        submitData(email, mobileNumber);
    };

    const submitData = (email, mobileNumber) => {
        const outputData = {
            userId,
            name,
            age: parseInt(age),
            height: parseInt(height),
            weight: parseInt(weight),
            email,
            mobileNumber,
            diagnosisTime,
            mainSymptoms,
            pcosDiagnosed: pcosDiagnosed === 0 ? 'No' : 'Yes',
            conceive: conceive === 0 ? 'No' : 'Yes',
            trialDate: '',
            trialType: '',
            trialLink: '',
            cisScore: ((cisScore / cisTotalScore) * 100).toFixed(1),
            irsScore: ((irsScore / irsTotalScore) * 100).toFixed(1),
            assScore: ((assScore / assTotalScore) * 100).toFixed(1),
            aesScore: ((aesScore / aesTotalScore) * 100).toFixed(1),
            utmSource: mode === 'onboard' ? 'App' : utm_source || 'NA',
            utmMedium: utm_medium || 'NA',
            utmCampaign: utm_campaign || 'NA',
            responses: responses.join('\n'),
        };

        var options = {
            method: 'POST',
            url: 'https://uvi-api.uvihealth.in/insertQuiz',
            headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
            form: outputData,
        };

        request(options, function (error, response, body) {
            if (error) console.log(error);
        });
    };

    const handleDataFromQuizlets = (context, value) => {
        if (context === 'hero') {
            setQuizProgress(quizProgress + 1);
        } else if (context === 'age') {
            setName(value.name);
            setAge(value.age);
            setQuizProgress(quizProgress + 1);
        } else if (context === 'pcos-diagnosis') {
            if (parseInt(value.value) === 0) {
                setPcosDiagnosed(value.value);
                setQuizScore(quizScore + value.value);
                setQuizTotalScore(quizTotalScore + value.totalValue);
                setQuizProgress(99);
            } else {
                setPcosDiagnosed(value.value);
                setQuizScore(quizScore + value.value);
                setQuizTotalScore(quizTotalScore + value.totalValue);
                setQuizProgress(quizProgress + 1);
            }
        } else if (context === 'diagnosis-time') {
            setDiagnosisTime(value.value);
            setQuizProgress(quizProgress + 1);
        } else if (context === 'main-symptoms') {
            setMainSymptoms(value.join(','));
            setQuizProgress(quizProgress + 1);
        } else if (context === 'height-weight') {
            setHeight(value.height);
            setWeight(value.weight);
            setQuizProgress(quizProgress + 1);
        } else if (context === 'trial') {
            if (value.data === 1) {
                setTrialDate(value.trialDate);
                setTrialType(value.trialType);
                setTrialLink(value.trialLink);
            }
            setQuizProgress(quizProgress + 1);
            submitData(value.trialDate, value.trialType, value.trialLink);
        } else if (context === 'multi-questions') {
            const currentMultiQuestions = JSON.parse(JSON.stringify(multiQuestions));
            currentMultiQuestions.push({
                answers: value.answers,
                scope: value.scope,
            });

            setResponses([...responses, `MCQ: ${value.answers.join(', ')}`]);

            setQuizScore(quizScore + value.value);
            setQuizTotalScore(quizTotalScore + value.totalValue);
            setQuizProgress(quizProgress + 1);

            if (value.scope === 'multi-1') {
                setCISScore(cisScore + value.value);
                setCISTotalScore(cisTotalScore + value.totalValue);
            }

            if (value.scope === 'multi-2' || value.scope === 'multi-3') {
                setIRSScore(irsScore + value.value);
                setIRSTotalScore(irsTotalScore + value.totalValue);
            }

            if (value.scope === 'multi-4' || value.scope === 'multi-5' || value.scope === 'multi-6') {
                setASSSCore(assScore + value.value);
                setASSTotalScore(assTotalScore + value.totalValue);
            }

            if (value.scope === 'multi-7') {
                setAESScore(aesScore + value.value);
                setAESTotalScore(aesTotalScore + value.totalValue);
            }
        } else if (context === 'fried-foods') {
            setFriedFoods(value.value);
            setQuizScore(quizScore + value.value);
            setQuizTotalScore(quizTotalScore + value.totalValue);
            setQuizProgress(quizProgress + 1);

            setResponses([...responses, `Fried-Foods: ${value.value === 1 ? 'Yes' : 'No'}`]);

            setCISScore(cisScore + value.value);
            setCISTotalScore(cisTotalScore + value.totalValue);
        } else if (context === 'food-allergies') {
            setFoodAllergies(value.value);
            setQuizScore(quizScore + value.value);
            setQuizTotalScore(quizTotalScore + value.totalValue);
            setQuizProgress(quizProgress + 1);

            setResponses([...responses, `Food-Allergies: ${value.value === 1 ? 'Yes' : 'No'}`]);

            setCISScore(cisScore + value.value);
            setCISTotalScore(cisTotalScore + value.totalValue);
        } else if (context === 'eating-disorders') {
            setEatingDisorders(value.value);
            setQuizScore(quizScore + value.value);
            setQuizTotalScore(quizTotalScore + value.totalValue);
            setQuizProgress(quizProgress + 1);

            setResponses([...responses, `Eating-Disorders: ${value.value === 1 ? 'Yes' : 'No'}`]);

            setASSSCore(assScore + value.value);
            setASSTotalScore(assTotalScore + value.totalValue);
        } else if (context === 'conceive') {
            setConceive(value.value);
            setQuizProgress(quizProgress + 1);
        }
    };

    const returnLandingSection = () => {
        return (
            <div>
                <LandingHero handleQuizSwitch={updateQuizMode} />
                <PCOSPlan />
                <HowItWorks />
                <LandingVideo handleTrialSwitch={updateFreeTrialMode} />
                <Testimonials />
                <Stats />
                <WhatToExpect />
                <UviMedia />
                <ConnectHealthCoach handleTrialSwitch={updateFreeTrialMode} />
            </div>
        );
    };

    const returnQuizSection = () => {
        switch (quizProgress) {
            case 0:
                return <QuizHero handleChange={handleDataFromQuizlets} />;
            case 1:
                return <QuizAge handleChange={handleDataFromQuizlets} />;
            case 2:
                return <PCOSDiagnosis handleChange={handleDataFromQuizlets} />;
            case 3:
                return <DiagnosisTime handleChange={handleDataFromQuizlets} />;
            case 4:
                return <MainSymptoms handleChange={handleDataFromQuizlets} />;
            case 5:
                return <QuizHeightWeight handleChange={handleDataFromQuizlets} />;
            case 6:
                return <Questions1 handleChange={handleDataFromQuizlets} />;
            case 7:
                return <QuizFriedFood handleChange={handleDataFromQuizlets} />;
            case 8:
                return <QuizFoodAllergies handleChange={handleDataFromQuizlets} />;
            case 9:
                return <Questions2 handleChange={handleDataFromQuizlets} />;
            case 10:
                return <Questions3 handleChange={handleDataFromQuizlets} />;
            case 11:
                return <Questions4 handleChange={handleDataFromQuizlets} />;
            case 12:
                return <Questions5 handleChange={handleDataFromQuizlets} />;
            case 13:
                return <Questions6 handleChange={handleDataFromQuizlets} />;
            case 14:
                return <QuizEatingDisorders handleChange={handleDataFromQuizlets} />;
            case 15:
                return <Questions7 handleChange={handleDataFromQuizlets} />;
            case 16:
                return <QuizConceive handleChange={handleDataFromQuizlets} />;
            case 17:
                return <QuizPreResult handleChange={handleUserInfo} />;
            case 19:
                return (
                    <QuizFinalResult
                        name={name}
                        scores={{
                            cisScore: (cisScore / cisTotalScore) * 100,
                            irsScore: (irsScore / irsTotalScore) * 100,
                            assScore: (assScore / assTotalScore) * 100,
                            aesScore: (aesScore / aesTotalScore) * 100,
                        }}
                        quizScore={quizScore}
                        quizTotalScore={quizTotalScore}
                        conceive={conceive}
                        age={age}
                        height={height}
                        weight={weight}
                        diagnosisTime={diagnosisTime}
                        mainSymptoms={mainSymptoms}
                        handleChange={handleUserInfo}
                    />
                );
            case 20:
                return (
                    <DirectReport
                        name={name}
                        scores={{
                            cisScore: (cisScore / cisTotalScore) * 100,
                            irsScore: (irsScore / irsTotalScore) * 100,
                            assScore: (assScore / assTotalScore) * 100,
                            aesScore: (aesScore / aesTotalScore) * 100,
                        }}
                        quizScore={quizScore}
                        quizTotalScore={quizTotalScore}
                        conceive={conceive}
                        age={age}
                        height={height}
                        weight={weight}
                        diagnosisTime={diagnosisTime}
                        mainSymptoms={mainSymptoms}
                    />
                );
            case 99:
                return <NoPCOS name={name} />;
            default:
                return <QuizHero handleChange={handleDataFromQuizlets} />;
        }
    };

    const returnFreeTrialSection = () => {
        return (
            <div>
                <FreeTrialAccess handleQuizSwitch={updateQuizMode} />
            </div>
        );
    };

    return (
        <div className="landing-container">
            <div className="container" id="container">
                <div className="col-12">
                    <img src="/assets/images/uvi-logo.svg" className="uvi-logo" alt="uvi-logo" />
                </div>

                {quizMode === 0
                    ? returnLandingSection()
                    : quizMode === 1
                    ? returnQuizSection()
                    : returnFreeTrialSection()}
            </div>
        </div>
    );
};

export default Home;
