import React from "react";

const HowItWorks2 = () => {
    return (
        <div className="how-it-works">
            <h3
                className="landing-home-hero-7"
                style={{ marginTop: 50, fontSize: 22 }}
            >
                How it works
            </h3>

            <div className="row">
                <div className="col-12 how-it-works-card">
                    <div className="row">
                        <div className="col-3">
                            <img
                                src="/assets/images/number-one.svg"
                                className="how-it-works-2-img"
                                alt="how-it-works-one"
                            />
                        </div>
                        <div className="col-9">
                            <p className="how-it-works-header">Share Details</p>
                            <p className="how-it-works-body">
                                Submit your name and WhatsApp number for us to
                                reach you
                            </p>
                        </div>
                    </div>
                </div>

                <div className="col-12 how-it-works-card">
                    <div className="row">
                        <div className="col-3">
                            <img
                                src="/assets/images/number-two.svg"
                                className="how-it-works-2-img"
                                alt="how-it-works-two"
                            />
                        </div>
                        <div className="col-9">
                            <p className="how-it-works-header">
                                Book your slot
                            </p>
                            <p className="how-it-works-body">
                                Share your health history and pick a slot that
                                works for you
                            </p>
                        </div>
                    </div>
                </div>

                <div className="col-12 how-it-works-card">
                    <div className="row">
                        <div className="col-3">
                            <img
                                src="/assets/images/number-three.svg"
                                className="how-it-works-2-img"
                                alt="how-it-works-three"
                            />
                        </div>
                        <div className="col-9">
                            <p className="how-it-works-header">
                                Get Zoom details
                            </p>
                            <p className="how-it-works-body">
                                We will WhatsApp you joining info
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default HowItWorks2;
