import React from "react";
import Slider from "react-slick";

const Experts = () => {
    const carouselSettings = {
        dots: false,
        arrows: false,
        infinite: true,
        slidesToShow: 2,
        slidesToScroll: 2,
        autoplay: true,
        autoplaySpeed: 0,
        speed: 5000,
        cssEase: "linear",
    };
    const experts = [
        {
            name: "Dr. Sheetal Aggarwal",
            experience: 33,
            specialization:
                "OBGYN, Infertility Specialist, Reproductive Endocrinologist",
            imgurl: "/assets/images/expert-1.png",
        },
        {
            name: "Dr. Amodita Ahuja",
            experience: 8,
            specialization: "OBGYN, Laprascopic Surgeon",
            imgurl: "/assets/images/expert-2.png",
        },
        {
            name: "Dr. Aditi Tandon",
            experience: 7,
            specialization:
                "OBGYN, Laprascopic Surgeon, Infertility Specialist",
            imgurl: "/assets/images/expert-3.png",
        },
        {
            name: "Dr. Deepa Nambiar",
            experience: 11,
            specialization: "OBGYN & Infertility Specialist",
            imgurl: "/assets/images/expert-4.png",
        },
        {
            name: "Dr. Theertha Shetty",
            experience: 8,
            specialization: "OBGYN",
            imgurl: "/assets/images/expert-5.png",
        },
        {
            name: "Prerna Mishra",
            experience: 3,
            specialization: "Clinical Psychologist",
            imgurl: "/assets/images/expert-6.png",
        },
        {
            name: "Garima Sharma",
            experience: 2,
            specialization: "Counselling Psychologist",
            imgurl: "/assets/images/expert-7.JPG",
        },
        {
            name: "Neha S Kumar",
            experience: 5,
            specialization: "Functional Nutritionist",
            imgurl: "/assets/images/expert-8.png",
        },
    ];

    return (
        <div>
            <div className="row" style={{ marginTop: "5em" }}>
                <div className="col-12">
                    <center>
                        <h2
                            style={{
                                fontWeight: 500,
                                fontSize: "2em",
                                color: "#4B4B6D",
                                marginBottom: "2em",
                            }}
                        >
                            Meet Our Experts
                        </h2>
                    </center>
                </div>
            </div>

            <Slider
                {...carouselSettings}
                responsive={[
                    {
                        breakpoint: 500,
                        settings: {
                            speed: 2000,
                            slidesToShow: 1,
                            slidesToScroll: 1,
                        },
                    },
                ]}
            >
                {experts.map((val) => {
                    return (
                        <div>
                            <div className="reviews-card-2" key={Math.random()}>
                                <img
                                    className="card-img-top review-card-img-2"
                                    src={val.imgurl}
                                    alt="Card cap"
                                />
                                <div>
                                    <h2
                                        style={{
                                            fontSize: 18,
                                            color: "#4B4B6D",
                                        }}
                                    >
                                        {val.name}
                                    </h2>
                                    <h4
                                        style={{
                                            fontSize: 16,
                                            color: "#969CBC",
                                            fontWeight: 400,
                                            marginTop: "1.2em",
                                        }}
                                    >
                                        {val.experience} years exp.
                                    </h4>
                                    <h4
                                        style={{
                                            fontSize: 16,
                                            color: "#4B4B6D",
                                            fontWeight: 500,
                                            marginTop: "1.2em",
                                        }}
                                    >
                                        {val.specialization}
                                    </h4>
                                </div>
                            </div>
                        </div>
                    );
                })}
            </Slider>
        </div>
    );
};

export default Experts;
