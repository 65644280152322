import React from "react";

const LandingVideo = ({ handleTrialSwitch }) => {
    const handleTrialClick = () => {
        handleTrialSwitch();
    };

    return (
        <div className="landing-video">
            <h2 className="landing-home-hero-1-1">
                Be inspired by someone like you
            </h2>

            <div className="landing-video-embed">
                <center>
                    <iframe
                        height="300"
                        title="Uvi Health Testimonial"
                        src="https://www.youtube.com/embed/jZ6Ph6RPkEo"
                    ></iframe>
                </center>
            </div>

            <h4 className="landing-home-hero-4">
                Namita was able to regularize her periods in 4 months
            </h4>
        </div>
    );
};

export default LandingVideo;
