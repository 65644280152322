import React, { useState } from "react";

const QuizAge = ({ handleChange }) => {
    const [name, setName] = useState("");
    const [age, setAge] = useState("");
    const [error, setError] = useState("");

    const handleAgeChange = (age) => {
        setAge(age);
    };

    const handleNameChange = (name) => {
        setName(name);
    };

    const handleNextClick = () => {
        function isNumeric(value) {
            return /^-?\d+$/.test(value);
        }

        if (name === "" || age === "") {
            setError("Please fill all details above before proceeding");
        } else if (!isNumeric(age) || parseInt(age) > 100 || parseInt(age) < 1)
            setError("Please enter a valid age");
        else {
            handleChange("age", { age, name });
        }
    };

    return (
        <div className="uvi-landing-main">
            <div className="progress progress-md">
                <div
                    className="progress-bar"
                    role="progressbar"
                    style={{ width: "7%", backgroundColor: "#DC4A9A" }}
                    aria-valuenow="7"
                    aria-valuemin="0"
                    aria-valuemax="100"
                ></div>
            </div>
            <h2 className="landing-home-hero-1">Hi, I am Uvi.</h2>
            <h2 className="landing-home-hero-1">
                Tell me a little bit about you.
            </h2>

            <h4 className="landing-home-hero-2">
                I promise - I don't share your answers with anyone.
            </h4>

            <div className="form-group row mb-3">
                <div className="col-12">
                    <input
                        autoComplete="off"
                        type="text"
                        className="form-control landing-page-form-element"
                        name="name"
                        placeholder="Type in your name here"
                        onChange={(e) => {
                            handleNameChange(e.target.value);
                        }}
                        value={name}
                    />
                </div>
            </div>

            <div className="form-group row mb-3">
                <div className="col-12">
                    <input
                        autoComplete="off"
                        type="text"
                        className="form-control landing-page-form-element"
                        name="name"
                        placeholder="How old are you?"
                        onChange={(e) => {
                            handleAgeChange(e.target.value);
                        }}
                        value={age}
                    />
                </div>
            </div>

            {error ? <h4 className="mcq-error-alert">{error}</h4> : null}

            <div>
                <div className="row">
                    <div className="col-12">
                        <button
                            className="btn btn-primary quiz-next"
                            onClick={handleNextClick}
                        >
                            Next
                        </button>
                    </div>
                </div>
            </div>
        </div >
    );
};

export default QuizAge;
