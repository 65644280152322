import React from "react";

const UviMedia = () => {
    return (
        <div className="uvi-health-media">
            <h2 className="landing-home-hero-1-1">Uvi Health is featured in</h2>

            <div className="row">
                <div className="col-4">
                    <img
                        src="/assets/images/accel-logo.svg"
                        className="uvi-media-img"
                        alt="uvi-media-accel"
                    />
                </div>

                <div className="col-4">
                    <img
                        src="/assets/images/yourstory-logo.svg"
                        className="uvi-media-img"
                        alt="uvi-media-yourstory"
                    />
                </div>

                <div className="col-4">
                    <img
                        src="/assets/images/inc-42-logo.svg"
                        className="uvi-media-img"
                        alt="uvi-media-inc42"
                    />
                </div>
            </div>

            <div
                className="row"
                style={{ marginTop: "2em", marginBottom: "2em" }}
            >
                <div className="col-6">
                    <img
                        src="/assets/images/entrepreneur-india-logo.svg"
                        className="uvi-media-img"
                        alt="uvi-media-entrepreneur"
                    />
                </div>

                <div className="col-6">
                    <img
                        src="/assets/images/qualcomm-logo.svg"
                        className="uvi-media-img"
                        alt="uvi-media-qualcomm"
                    />
                </div>
            </div>
        </div>
    );
};

export default UviMedia;
