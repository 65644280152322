import React, { useState } from "react";
import Slider from "react-slick";
import styles from "../../styleModules/style.module.css";

const Media = () => {
    const pressData = [
        {
            imgSrc: "/assets/images/media-1.png",
            name: "Entrepreneur",
            head: "Femtech startups want to change women's healthcare in India",
            date: "July 19, 2021",
            content: `Digital health startup for women Uvi Health has raised $330,000 in a pre-seed round of funding led by Titan Capital, 9Unicorns, AngelList, and a clutch of angels, including Ghazal Alagh, co-founder of Mamaearth, Aarti Gill, and Mihir Gadani, founders of Oziva.`,
        },
        {
            imgSrc: "/assets/images/media-2.png",
            name: "Economic Times",
            head: "Uvi Health raises pre-seed round of funding",
            date: "Aug 21, 2021",
            content: `Danielle Ann Alves, a 32-year-old in Mumbai, had
            been going to doctors for PCOS and thyroid since
            2013. Finally, it was a customised fitness and meal
            plan by Uvi Health, which she came across by chance
            online, that helped her thyroid condition, she says.
            “The trainers keep checking in on you. That helped,”
            she says.`,
        },
        {
            imgSrc: "/assets/images/your-story.png",
            name: "YourStory",
            head: "These women-led startups are ensuring Indian women stay on top of their health goals",
            date: "Sep 8, 2021",
            content: `Based in Bengaluru, the startup ﻿Uvi Health﻿ seeks to change the trust, accountability, and consistent experience in reproductive health services. The online platform works with a team of gynaecologists, nutritionists, and fitness experts to build a customised treatment pathway for women with PCOS.`,
        },
    ];
    const [currentSlide, setCurrentSlide] = useState(0);

    const carouselSettings = {
        dots: true,
        arrows: false,
        infinite: true,
        slidesToShow: 2,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 0,
        speed: 3000,
        afterChange: (current) => setCurrentSlide(current),
        customPaging: (i) => {
            return (
                <div
                    className={
                        i === currentSlide ? "current_dot" : "carousel_dot"
                    }
                ></div>
            );
        },
    };

    return (
        <div>
            <div className="row" style={{ marginTop: "5em" }}>
                <div className="col-12">
                    <center>
                        <h2
                            style={{
                                fontWeight: 500,
                                fontSize: "2em",
                                color: "#4B4B6D",
                                marginBottom: "2em",
                            }}
                        >
                            Simplifying hormonal health for women
                        </h2>
                    </center>
                </div>
            </div>

            <Slider
                {...carouselSettings}
                responsive={[
                    {
                        breakpoint: 500,
                        settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1,
                        },
                    },
                ]}
            >
                {pressData.map((data) => {
                    return (
                        <div>
                            <div className={styles.pressCard}>
                                <img src={data.imgSrc} alt="pcos-4" />

                                <h4 className={styles.pressCard_name}>
                                    {data.name}
                                </h4>

                                <h4 className={styles.pressCard_head}>
                                    {data.head}
                                </h4>

                                <h4 className={styles.pressCard_date}>
                                    {data.date}
                                </h4>

                                <h4 className={styles.pressCard_content}>
                                    {data.content}
                                </h4>
                            </div>
                        </div>
                    );
                })}
            </Slider>
        </div>
    );
};

export default Media;
