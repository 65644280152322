import React from "react";

const WholeBodyApproach = () => {
    return (
        <div className="row" style={{ marginTop: "5em" }}>
            <div className="col-12">
                <center>
                    <h2
                        style={{
                            fontWeight: 500,
                            fontSize: "2em",
                            color: "#4B4B6D",
                            marginBottom: "2em",
                        }}
                    >
                        A whole body approach to healing
                    </h2>
                </center>
            </div>

            <div className="col-md-4 col-xs-12">
                <div
                    style={{
                        background: "rgba(255, 238, 234, 0.28)",
                        border: "2px solid #FFE6E1",
                        boxSizing: "border-box",
                        borderRadius: 24,
                        padding: 15,
                        marginTop: 10,
                        marginBottom: 10,
                    }}
                >
                    <center>
                        <img
                            src="/assets/images/pcos-landing-img-1.png"
                            alt="pcos-1"
                            style={{
                                margin: 20,
                                width: "60%",
                            }}
                        />

                        <h3
                            style={{
                                marginTop: 20,
                                fontSize: "1em",
                                color: "#4B4B6D",
                            }}
                        >
                            Live & Interactive PCOS Fitness Sessions
                        </h3>
                    </center>
                </div>
            </div>

            <div className="col-md-4 col-xs-12">
                <div
                    style={{
                        background: "rgba(255, 238, 234, 0.28)",
                        border: "2px solid #FFE6E1",
                        boxSizing: "border-box",
                        borderRadius: 24,
                        padding: 15,
                        marginTop: 10,
                        marginBottom: 10,
                    }}
                >
                    <center>
                        <img
                            src="/assets/images/pcos-landing-img-2.png"
                            alt="pcos-1"
                            style={{
                                margin: 20,
                                width: "60%",
                            }}
                        />

                        <h3
                            style={{
                                marginTop: 20,
                                fontSize: "1em",
                                color: "#4B4B6D",
                            }}
                        >
                            1:1 Nutrition Planning and Support
                        </h3>
                    </center>
                </div>
            </div>

            <div className="col-md-4 col-xs-12">
                <div
                    style={{
                        background: "rgba(255, 238, 234, 0.28)",
                        border: "2px solid #FFE6E1",
                        boxSizing: "border-box",
                        borderRadius: 24,
                        padding: 15,
                        marginTop: 10,
                        marginBottom: 10,
                    }}
                >
                    <center>
                        <img
                            src="/assets/images/pcos-landing-img-3.png"
                            alt="pcos-1"
                            style={{
                                margin: 20,
                                width: "60%",
                            }}
                        />

                        <h3
                            style={{
                                marginTop: 20,
                                fontSize: "1em",
                                color: "#4B4B6D",
                            }}
                        >
                            Consultations with experts
                        </h3>
                    </center>
                </div>
            </div>
        </div>
    );
};

export default WholeBodyApproach;
