import React from "react";

const Testimonials = () => {
    const reviews = [
        {
            program: "Yoga for PCOS Subscriber",
            description:
                "I have observed improvement in my body digestion, stamina within 2 months. This is the reason I was able to conceive easily.",
            name: "Sravani, Hyderabad",
            dp: "https://i.ibb.co/c66qjht/sravani-hyd.png"
        },
        {
            program: "PCOS Program Subscriber",
            description:
                "As a working woman tackling PCOS has been a challenge for me, Uvi Health’s overall support system easily fits in my busy routine.",
            name: "Sharanya, Bangalore",
            dp: "https://i.ibb.co/fSTTB5B/sharanya-blr.png"
        },
        {
            program: "Yoga for PCOS Subscriber",
            description: "I have seen changes in my body. I have lost weight and improved my stamina.",
            name: "Harita, Hyderabad",
            dp: "https://i.ibb.co/Y7nYf8k/harita-hyd.png"
        },
        {
            program: "PCOS Program Subscriber",
            description: "The best thing is that I got my period on time, which is a big concern for me every month.",
            name: "Pooja Sachdeva, Delhi",
            dp: "https://i.ibb.co/yh4XDHk/pooja-del.png"
        },
        {
            program: "PCOS Program Subscriber",
            description:
                "The call with the doctor was calming. She made me understand a lot of things properly after knowing my symptoms.",
            name: "Pratishtha, Jaipur",
            dp: null
        },
        {
            program: "PCOS Program Subscriber",
            description:
                "I really like the fact that Uvi’s PCOS plan is so personalised and so much attention is given to me.",
            name: "Danielle, Mumbai",
            dp: null
        }
    ];

    const returnTestimonials = () => {
        return reviews.map(val => {
            return (
                <div className="reviews-card" key={Math.random()}>
                    {val.dp ? (
                        <img src={val.dp} className="testimonial-dp" alt="uvi-testimonial-dp" />
                    ) : (
                        <div className="empty-dp" />
                    )}
                    <p className="testimonial-header">{val.name}</p>
                    <p className="testimonial-body">{val.description}</p>
                    <p className="testimonial-footer">{val.program}</p>
                </div>
            );
        });
    };

    return (
        <div className="testimonial">
            <h2 className="landing-home-hero-1-1">Read more stories of PCOS healing</h2>

            <div className="horizontal-row">{returnTestimonials()}</div>
        </div>
    );
};

export default Testimonials;
