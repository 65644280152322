import React, { useState } from "react";

const Questions2 = ({ handleChange }) => {
    const [answers, setAnswers] = useState([]);

    const setAnswer = (answer) => {
        const currentAnswers = JSON.parse(JSON.stringify(answers));

        if (currentAnswers.includes(answer)) {
            const index = currentAnswers.indexOf(answer);
            currentAnswers.splice(index, 1);
        } else {
            currentAnswers.push(answer);
        }

        setAnswers(currentAnswers);
    };

    const handleNextClick = () => {
        handleChange("multi-questions", {
            value: answers.length,
            totalValue: baseAnswers.length,
            answers,
            scope: "multi-2",
        });
    };

    const baseAnswers = [
        "My weight gain has predominantly been in the abdomen region",
        "I have sugar cravings",
        "I have carb cravings (bread, rice or potato)",
        "I don't feel full easily or eat too much in one sitting, or binge eat at times",
        "I have family history of diabetes",
    ];

    const renderAnswers = () => {
        return baseAnswers.map((val) => {
            return (
                <div className="col-12" key={Math.random()}>
                    <div
                        className={
                            answers.includes(val)
                                ? "answer-multi-selected"
                                : "answer-multi"
                        }
                        onClick={() => setAnswer(val)}
                    >
                        {val}
                    </div>
                </div>
            );
        });
    };

    return (
        <div className="uvi-landing-main">
            <div className="progress progress-md">
                <div
                    className="progress-bar"
                    role="progressbar"
                    style={{ width: "49%", backgroundColor: "#DC4A9A" }}
                    aria-valuenow="49"
                    aria-valuemin="0"
                    aria-valuemax="100"
                ></div>
            </div>
            <h2 className="landing-home-hero-1">
                Which of the following apply to you?
            </h2>

            <div className="row answer-section">{renderAnswers()}</div>

            <div>
                <div className="row">
                    <div className="col-12">
                        <button
                            className="btn btn-primary quiz-next"
                            onClick={handleNextClick}
                        >
                            Next
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Questions2;
