import React from "react";

const LandingHero = () => {
    return (
        <div className="row" style={{ marginTop: "5em" }}>
            <div className="col-md-6 col-xs-12" style={{ marginBottom: 20 }}>
                <h1
                    style={{
                        fontWeight: 500,
                        fontSize: "3em",
                        color: "#4B4B6D",
                    }}
                >
                    Your path to <br />
                    PCOS reversal
                </h1>
                <h3
                    style={{
                        marginTop: "1.7em",
                        fontWeight: 400,
                        fontSize: "1.7em",
                        lineHeight: "1.2em",
                        color: "#4B4B6D",
                    }}
                >
                    Get a science-backed plan, built by experts to{" "}
                    <b>tackle the root cause of your PCOS</b>
                </h3>
                <h5
                    style={{
                        fontWeight: 300,
                        fontSize: 22,
                        marginTop: "1.2em",
                        color: "#F5735A",
                    }}
                >
                    <em>Trusted by hundreds of women</em>
                </h5>
            </div>
            <div className="col-md-6 col-xs-12">
                <img
                    src="/assets/images/pcos-landing-hero.png"
                    alt="uvi-logo"
                    style={{
                        float: "right",
                        maxWidth: "100%",
                    }}
                />
            </div>
        </div>
    );
};

export default LandingHero;
