import React from "react";

const PCOSPlan = () => {
    return (
        <div className="pcos-plan-details">
            <h4 className="landing-home-hero-7">Our plan offers</h4>
            <h4 className="landing-home-hero-8">
                a personalized routine for your PCOS root cause
            </h4>

            <div className="pcos-plan-card">
                <div className="row">
                    <div
                        className="col-3"
                        style={{ display: "flex", justifyContent: "center" }}
                    >
                        <img
                            src="https://i.ibb.co/BtDJD62/nutrition.png"
                            className="pcos-plan-img"
                            alt="pcos-plan-nutrition"
                        />
                    </div>
                    <div className="col-9">
                        <p className="pcos-plan-header">Nutrition</p>
                        <p className="pcos-plan-body">
                            1:1 consult with Nutrition Coach
                        </p>
                        <p className="pcos-plan-body">Tailor-made plan</p>
                        <p className="pcos-plan-body">
                            Daily WhatsApp tracking
                        </p>
                    </div>
                </div>
            </div>

            <div className="pcos-plan-card">
                <div className="row">
                    <div
                        className="col-3"
                        style={{ display: "flex", justifyContent: "center" }}
                    >
                        <img
                            src="https://i.ibb.co/Dzgyqk9/fitness.png"
                            className="pcos-plan-img"
                            alt="pcos-plan-fitness"
                        />
                    </div>
                    <div className="col-9">
                        <p className="pcos-plan-header">Fitness</p>
                        <p className="pcos-plan-body">
                            Live Zoom workout sessions
                        </p>
                        <p className="pcos-plan-body">Flexible scheduling</p>
                        <p className="pcos-plan-body">
                            150+ live sessions to choose from
                        </p>
                    </div>
                </div>
            </div>

            <div className="pcos-plan-card">
                <div className="row">
                    <div
                        className="col-3"
                        style={{ display: "flex", justifyContent: "center" }}
                    >
                        <img
                            src="https://i.ibb.co/pXvLWzK/doctor.png"
                            className="pcos-plan-img"
                            alt="pcos-plan-consult"
                        />
                    </div>
                    <div className="col-9">
                        <p className="pcos-plan-header">Specialist Consults</p>
                        <p className="pcos-plan-body">OBGYN consult</p>
                        <p className="pcos-plan-body">
                            Add-on dermatologist consult
                        </p>
                        <p className="pcos-plan-body">
                            Unlimited support from Health Coach
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PCOSPlan;
