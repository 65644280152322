import classNames from "classnames";
import React from "react";
import styles from "../../styleModules/style.module.css";

const LandingHero = () => {
    return (
        <div className={classNames(styles.row)}>
            <div className="col-md-6 col-xs-12">
                <h1 className={classNames(styles.head1, styles.hero_head)}>
                    Your path to <br />
                    PCOS reversal
                </h1>
                <h3 className={classNames(styles.head3)}>
                    Get a science-backed plan, built by experts to{" "}
                    <b>tackle the root cause of your PCOS</b>
                </h3>
                <h5 className={classNames(styles.head5)}>
                    <em>Trusted by hundreds of women</em>
                </h5>
            </div>
            <div className={styles.hero_img_container}>
                <img
                    src="/assets/images/pcos-landing-hero.png"
                    alt="uvi-logo"
                    className={styles.hero_image}
                />
            </div>
        </div>
    );
};

export default LandingHero;
