import React, { useState } from "react";
import faqStyles from "../../styleModules/faq.module.css";

const FAQ = () => {
    const [activeAccordion, setActiveAccordion] = useState("");

    const faqData = [
        {
            que: "Can PCOS be cured?",
            ans: () => {
                return (
                    <>
                        "PCOS can not be cured, however, its symptoms can be
                        managed.
                        <br />
                        <br />
                        Often, birth control pills are used for symptom
                        management. However, hormonal birth control pills do not
                        attack the root cause of the problem and stops your
                        body's natural ovulation.
                        <br />
                        <br />
                        At Uvi Health, we use science-backed techniques to
                        manage your PCOS symptoms through lifestyle changes like
                        diet, exercise, supplementation, stress, and sleep
                        management. Treatment of PCOS can differ depending on
                        your root cause or triggers. Uvi Health's PCOS
                        Management program is designed keeping this in mind.
                        Through our customized program, we build a plan mapped
                        to your root cause and healthcare goals."
                    </>
                );
            },
        },
        {
            que: "What are the root causes of PCOS?",
            ans: () => {
                return (
                    <>
                        "PCOS can happen due to genetic, metabolic or
                        environmental factors. Understanding your triggers is a
                        very important step in planning your treatment strategy.
                        With our team of doctors and experts, we have created a
                        simple quiz that you can use to understand the root
                        causes of your PCOS. While there could be many other
                        contributing factors, the following are the four primary
                        root causes of PCOS:
                        <br />
                        <br />
                        1. Insulin Resistance: Being insulin-resistant means
                        that your body doesn't respond well to the insulin. As a
                        result, your pancreas make more insulin to help glucose
                        enter your cells. Increase in insulin triggers your
                        ovaries to produce more androgen, or male hormones.
                        <br />
                        <br />
                        2. Inflammation: Inflammation happens when your body's
                        natural immune system reacts to a foreign substance.
                        Some foods can trigger inflammation in the body. Chronic
                        inflammation triggers your ovaries to make too much
                        testosterone and can lead to irregular periods.
                        <br />
                        <br />
                        3. Adrenals and Stress: When you're understand extreme
                        or chronic stress, your body's immune system responds by
                        releasing more adrenaline and cortisol. This response
                        also causes the ovaries to increase its androgen
                        production.
                        <br />
                        <br />
                        4. Excess Androgen: Excess production of androgen, also
                        referred to as “male hormone”, can contribute to
                        abnormal body hair, acne, increase in weight and hair
                        loss.
                        <br />
                        <br />
                        Interested in finding out your root cause? Book a
                        consult with our PCOS Expert to learn more. "
                    </>
                );
            },
        },
        {
            que: "I am on some medication for PCOS. Can I still get on Uvi's PCOS plan?",
            ans: () => {
                return (
                    <>
                        Our plan focuses on natural healing and is designed to
                        reduce your dependence on medication. However, you can
                        still participate in our Plan if you're on medication.
                    </>
                );
            },
        },
    ];

    const FAQCard = ({ que, ans, activeAccordion, setActiveAccordion }) => {
        const isOpen = activeAccordion === que;
        return (
            <div
                className={faqStyles.faq_card}
                onClick={() => setActiveAccordion(isOpen ? "" : que)}
            >
                <div className="">
                    <h5>
                        <div className={faqStyles.que}>
                            {`Q ${que}`}
                            <i
                                className={`${
                                    isOpen ? "uil-minus" : "uil-plus"
                                } `}
                            ></i>
                        </div>
                    </h5>
                </div>
                {isOpen && <div className="">{ans()}</div>}
            </div>
        );
    };

    return (
        <div className="row" style={{ marginTop: "5em" }}>
            <div className="col-12">
                <center>
                    <h2
                        style={{
                            fontWeight: 500,
                            fontSize: "2em",
                            color: "#4B4B6D",
                            marginBottom: "1em",
                        }}
                    >
                        Frequently Asked Questions
                    </h2>
                </center>
            </div>
            <div className="col-12">
                <div
                    className="accordion custom-accordion"
                    id="custom-accordion-one"
                >
                    {faqData.map((f) => {
                        return (
                            <FAQCard
                                que={f.que}
                                ans={f.ans}
                                activeAccordion={activeAccordion}
                                setActiveAccordion={setActiveAccordion}
                            />
                        );
                    })}
                </div>
            </div>
        </div>
    );
};

export default FAQ;
