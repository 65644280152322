import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import request from "request";
import axios from "axios";
import queryString from "query-string";

import TrialIntro from "./trial-intro";
import TrialInfo from "./trial-info";
import TrialBooking from "./trial-booking";

const Home = () => {
    const userId = "UVIQL" + String(Date.now());
    const [quizProgress, setQuizProgress] = useState(0);
    const [paid, setPaid] = useState(0);
    const { utm_source, utm_medium, utm_campaign } = queryString.parse(
        window.location.search
    );

    const [name, setName] = useState("");
    const [age, setAge] = useState("");
    const [email, setEmail] = useState("");
    const [mobileNumber, setMobileNumber] = useState("");
    const [height, setHeight] = useState("");
    const [weight, setWeight] = useState("");
    const [city, setCity] = useState("");
    const [history, setHistory] = useState("");
    const [session, setSession] = useState("PCOS Yoga");
    const [fitnessLevel, setFitnessLevel] = useState("Beginner");
    const [workouts, setWorkouts] = useState("-");
    const [trialDate, setTrialDate] = useState("");
    const [trialType, setTrialType] = useState("");
    const [trialLink, setTrialLink] = useState("");

    const returnPaymentSuccess = () => {
        return (
            <div className="uvi-landing-main">
                <h2
                    className="landing-home-hero-1"
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        marginBottom: "2em",
                    }}
                >
                    Your trial is booked 🎉
                </h2>
                <div
                    style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        marginBottom: "2em",
                    }}
                >
                    <img
                        src="/assets/images/green-tick.png"
                        className="payment-success-img"
                        alt="uvi-hero"
                    />
                </div>

                <center>
                    <h4 className="landing-home-hero-2">
                        We have sent more information regarding your session on
                        your WhatsApp. See you soon!
                    </h4>
                </center>

                <a
                    className="btn btn-primary landing-page-submit btn-block"
                    href="https://www.uvihealth.in"
                >
                    <span className="landing-submit-text">
                        Explore Uvi Health
                    </span>
                </a>
            </div>
        );
    };

    const loadScript = (src) => {
        return new Promise((resolve) => {
            const script = document.createElement("script");
            script.src = src;
            script.onload = () => {
                resolve(true);
            };
            script.onerror = () => {
                resolve(false);
            };
            document.body.appendChild(script);
        });
    };

    const triggerPayment = async (trialDate, trialType, trialLink) => {
        const outputData = {
            userId,
            name,
            age,
            height,
            weight,
            email,
            mobileNumber,
            city,
            fitnessLevel,
            history,
            workouts,
            trialTimestamp: trialDate,
            trialType,
            trialLink,
            utmSource: utm_source,
            utmMedium: utm_medium,
            utmCampaign: utm_campaign,
        };

        setPaid(1);
        console.log(outputData);

        var options_trial_book = {
            method: "POST",
            url: "https://uvi-api.uvihealth.in/bookTrialOrder",
            headers: {
                "Content-Type": "application/x-www-form-urlencoded",
            },
            form: outputData,
        };

        request(options_trial_book, function (error, response, body) {
            if (error) console.log(error);
        });
    };

    const handleDataFromQuizlets = (context, value) => {
        if (context === "hero") {
            setQuizProgress(quizProgress + 1);
        } else if (context === "info") {
            setName(value.output.name);
            setAge(value.output.age);
            setEmail(value.output.email);
            setMobileNumber(value.output.mobileNumber);
            setSession(value.output.session);
            setHeight(value.output.height);
            setWeight(value.output.weight);
            setCity(value.output.city);
            setFitnessLevel(value.output.fitnessLevel);
            setWorkouts(value.output.workouts);
            setHistory(value.output.history);

            setQuizProgress(quizProgress + 2);
        } else if (context === "trial") {
            const trialDate = value.trialDate;
            const trialType = value.trialType;
            const trialLink = value.trialLink;

            setTrialDate(trialDate);
            setTrialType(trialType);
            setTrialLink(trialLink);

            triggerPayment(trialDate, trialType, trialLink);
        }
    };

    const returnQuizSection = () => {
        switch (quizProgress) {
            case 0:
                return <TrialIntro handleChange={handleDataFromQuizlets} />;
            case 1:
                return <TrialInfo handleChange={handleDataFromQuizlets} />;
            case 2:
                return (
                    <TrialBooking
                        handleChange={handleDataFromQuizlets}
                        sessionType={session}
                    />
                );
            default:
                return <TrialIntro handleChange={handleDataFromQuizlets} />;
        }
    };

    return (
        <div className="landing-container">
            <div className="container" id="container">
                <div className="col-12">
                    <img
                        src="/assets/images/uvi-logo.svg"
                        className="uvi-logo"
                        alt="uvi-logo"
                    />
                </div>

                {paid === 1 ? returnPaymentSuccess() : returnQuizSection()}
            </div>
        </div>
    );
};

export default Home;
