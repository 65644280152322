import React from "react";

const NoPCOS = ({ name }) => {
    return (
        <div className="no-pcos">
            <div className="no-pcos-card">
                <h2 className="landing-home-hero-1-1">
                    {name.split(" ")[0]}, at this time you’re not eligible to
                    take the PCOS root cause analysis
                </h2>

                <h4
                    className="landing-home-hero-2-2"
                    style={{ color: "#969cbc" }}
                >
                    We recommend you connect with a Health Coach in our team who
                    will discuss your symptoms and help you get a formal
                    diagnosis.
                </h4>

                <div className="row">
                    <div className="col-12">
                        <a
                            className="btn btn-primary landing-page-submit btn-block"
                            href="https://calendly.com/uvihealth/healthcoach?utm_medium=web&utm_source=landing-quiz"
                        >
                            <span className="landing-submit-text">
                                Book Health Coach Call
                            </span>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default NoPCOS;
