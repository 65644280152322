import React from "react";
import classNames from "classnames";
import styles from "../../styleModules/style.module.css";

const Testimonial = () => {
    return (
        <div className={classNames(styles.testimonial_container)}>
            <h3
                className={classNames(styles.head2, styles.mob_show)}
                style={{ margin: "0" }}
            >
                Namita{" "}
                <span style={{ color: "#F5735A" }}>reversed her PCOS</span> in
                86 days{" "}
            </h3>
            <div>
                <video
                    width="300"
                    height="300"
                    poster="/assets/testimoinals/namita.png"
                    style={{
                        border: "1px solid #F5735A",
                        borderRadius: "18px",
                    }}
                    controls={true}
                    autoPlay={true}
                >
                    <source
                        src="/assets/testimoinals/namita.mp4"
                        type="video/mp4"
                    />
                </video>
            </div>
            <div className={styles.content_container}>
                <h3
                    className={classNames(styles.head2, styles.mob_hide)}
                    style={{ margin: "0" }}
                >
                    Namita{" "}
                    <span style={{ color: "#F5735A" }}>reversed her PCOS</span>{" "}
                    in 86 days{" "}
                </h3>
                <div className={styles.content_flex}>
                    <div className={styles.content1}>
                        The team is very responsive and helpful! I especially
                        love the yoga sessions, the instructors are great. My
                        Health Coach caters to all my needs and keeps motivating
                        me from time to time. The journey with Uvi has so far
                        been amazing.
                    </div>
                    <div className={styles.details}>
                        <b>Namita Shukla, Delhi,</b>
                        <br /> 22 year old <br />
                        <span
                            style={{
                                fontSize: "16px",
                                color: "#F5735A",
                            }}
                        >
                            5 years of PCOS history
                        </span>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Testimonial;
